import Skeleton from 'react-loading-skeleton'
import './SkeletonSwiperComponent.scss'

function SkeletonSwiperComponent() {
    return (
        <div className='skeleton-swiper'>
            <Skeleton height={32} width={300} style={{ opacity: 0.6 }} className='title' />
            <Skeleton borderRadius={25} count={3} inline={true} style={{ opacity: 0.6 }} containerClassName='swiper' />
        </div>
    )
}

export default SkeletonSwiperComponent