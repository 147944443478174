import { useAttributeCategoryTranslation } from "@/general/i18n/translation-helpers";
import { AttributeCategory } from "@/general/model/attribute-category";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import { t } from "i18next";
import { ProgressBar } from "react-bootstrap";
import './ProfileRequirementCategoryCompletionTile.scss';
import RequirementTaskList from "./RequirementTaskList";
import Skeleton from "react-loading-skeleton";
import DashboardTile from "./DashboardTile";

type ProfileRequirementCategoryCompletionTileProps = {
    attributeCategory: AttributeCategory
    tileClassName?: string
}

function ProfileRequirementCategoryCompletionTile({ attributeCategory, tileClassName }: ProfileRequirementCategoryCompletionTileProps) {

    const { translateAttributeCategory } = useAttributeCategoryTranslation();
    const { profileCompletionRequirements, totalCompletion, isLoading } = useProfileCompletionRequirements();
    const requirementCategory = profileCompletionRequirements?.requirementCategories.find(x => x.attributeCategory == attributeCategory);

    function getDescription(percentCompleted: number) {
        if (percentCompleted === 0) {
            return 'dashboard:requirementCategoryCompletion:descriptionNoProgress';
        } else if (percentCompleted < 100) {
            return 'dashboard:requirementCategoryCompletion:descriptionInProgress';
        } else {
            return 'dashboard:requirementCategoryCompletion:descriptionCompleted';
        }
    }

    return (
        <DashboardTile tileClassName={`${tileClassName}`}  contentClassName='profile-requirement-category-completion-tile-content'>

            <h2 className="title">{translateAttributeCategory(attributeCategory)}</h2>

            <div className={`category-progress ${requirementCategory?.percentCompleted == 100 && 'category-progress--completed'}`}>
                {isLoading
                    ? <Skeleton height={24} />
                    : <ProgressBar now={requirementCategory.percentCompleted} label={`${requirementCategory.percentCompleted}%`} />
                }
            </div>

            <div className="info">
                {isLoading &&
                    <>
                        <Skeleton height={48} className="mb-3" />
                        <Skeleton height={24} width={200} className="mb-2" />
                        <Skeleton count={3} height={24} width={'75%'} />
                    </>
                }

                {!isLoading && requirementCategory &&
                    <>
                        <p className="description">{t(getDescription(requirementCategory.percentCompleted))}</p>

                        {requirementCategory.percentCompleted < 100 &&
                            <RequirementTaskList requirementCategory={requirementCategory} />
                        }

                        {requirementCategory.percentCompleted == 100 &&
                            <div className="completed">
                                <i className="icon fas fa-check-circle"></i>
                            </div>
                        }
                    </>
                }
            </div>
        </DashboardTile>
    )
}

export default ProfileRequirementCategoryCompletionTile