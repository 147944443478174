import ProfessionCard from '@/components/cards/ProfessionCard';
import FilterComponent from '@/components/filter-component/FilterComponent';
import SearchComponent from '@/components/search/SearchComponent';
import SortComponent from '@/components/sort-component/SortComponent';
import useAllProfessions from '@/hooks/useAllProfessions';
import { t } from 'i18next';
import { useCallback, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import './ProfessionList.scss';

function ProfessionList() {

    const { professions, totalCount, isLoading, filterOptions, updateSearchterm, updateSorting, updateFilters, loadMore } = useAllProfessions();

    const sortingOptions = [
        { label: t('professions:overview:listSortNameAsc'), value: 'name asc' }
    ];

    const observer = useRef<IntersectionObserver | null>(null);
    const lastProfessionElementRef = useCallback(node => {
        if (isLoading || professions.length >= totalCount) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                loadMore();
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading]);

    return (
        <>
            <div className="profession-list" id='list'>
                <Row>
                    <Col sm={12} md={12} lg={12} xl={12} xxl={12} className='profession-list__header d-flex flex-column flex-lg-row justify-content-between align-items-center mb-5'>
                        <span className='d-flex flex-column pb-3 pb-lg-0'>
                            <span className='profession-list__title'>{t('professions:overview:listTitle')}</span>
                            <span className='profession-list__result-count'>{t('professions:overview:listResultCount', { count: professions.length, total: totalCount })}</span>
                        </span>

                        <div className='d-flex gap-3 flex-column flex-md-row'>
                            <FilterComponent options={filterOptions} onFiltersChange={(value) => updateFilters(value)} />
                            <SortComponent options={sortingOptions} onSortChange={(value) => updateSorting(value)} />
                            <SearchComponent placeholder={t('professions:overview:listSearchPlaceholder')} onSearchChange={(value) => updateSearchterm(value)} />
                        </div>
                    </Col>
                </Row>

                {
                    professions &&
                    <Row>
                        {professions.length > 0 &&
                            professions.map((profession, index) => (
                                <Col ref={index === professions.length - 1 ? lastProfessionElementRef : null} sm={12} md={6} lg={4} key={profession.id} className='mb-5'>
                                    <ProfessionCard profession={profession} />
                                </Col>
                            ))
                        }
                    </Row>
                }

                {isLoading &&
                    <Row>
                        <Col sm={12} className='py-4 text-center text-paars'>
                            <i className="fas fa-circle-notch fa-spin fa-3x"></i>
                        </Col>
                    </Row>
                }

                {professions.length == 0 && !isLoading &&
                    <Col sm={12} className='profession-list__no-results'>
                        <p>{t('professions:overview:listSearchNoResults')}</p>
                    </Col>
                }
            </div>
        </>
    )
}

export default ProfessionList