import { Container } from 'react-bootstrap'
import './PageHeader.scss'

type HeaderFullProps = {
    image: string,
    lightTheme: boolean
    children: any
}

function PageHeader({ image, lightTheme, children }: HeaderFullProps) {
    return (
        <Container fluid className="page-header py-0">
            <Container className='page-header-inner'>
                <div className='background-img px-0'>
                    <img src={image} alt='' />
                </div>
                {children}
            </Container>
        </Container>
    )
}

export default PageHeader