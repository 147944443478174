import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuDashboardIcon, MenuLearningIcon, MenuMeasurementIcon, MenuMoreIcon, MenuProfessionGuideIcon, MenuProfessionIcon, MenuProfileIcon, MenuSettingsIcon, MenuStudyGuideIcon } from './components/menu-icons';
import './navigation-bar.scss';
import { Col, Container, Row } from 'react-bootstrap';

function NavigationBar() {

    const [mobilePopupOpen, setMobilePopupOpen] = useState(false);

    useEffect(() => {
        // Function to close popup when clicking outside
        const closePopup = (event) => {

            // Check if the click occurred on the button to open the popup
            if (event.target.closest('.more-button')) {
                return;
            }

            // Check if the click occurred on a link within the popup
            if (event.target.closest('.mobile-popup a')) {
                setMobilePopupOpen(false);
                return;
            }

            if (!event.target.closest('.mobile-popup') && mobilePopupOpen) {
                setMobilePopupOpen(false);
            }
        };

        // Add event listener
        document.addEventListener('click', closePopup);

        // Cleanup function
        return () => {
            document.removeEventListener('click', closePopup);
        };
    }, [mobilePopupOpen]);

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <aside className={`navigation-bar`}>
                        <nav className="navigation-bar-nav">
                            <ul className='navigation-bar-links position-relative'>
                                <li>
                                    <NavLink
                                        to="/"
                                        className="nav-link"
                                    >
                                        <MenuDashboardIcon width="32" height="32" />
                                        <span>{t('navigationBar:dashboard')}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/development"
                                        className="nav-link"
                                    >
                                        <MenuProfileIcon width="32" height="32" />
                                        <span>{t('navigationBar:development')}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/measurements"
                                        className="nav-link"
                                    >
                                        <MenuMeasurementIcon width="32" height="32" />
                                        <span>{t('navigationBar:measurements')}</span>
                                    </NavLink>
                                </li>
                                <li className='d-block d-sm-none'>
                                    <a
                                        className="nav-link more-button"
                                        onClick={() => setMobilePopupOpen(!mobilePopupOpen)}
                                    >
                                        <MenuMoreIcon width="32" height="30" />
                                        <span>{t('navigationBar:more')}</span>
                                    </a>
                                </li>
                                <div className={`mobile-popup d-none d-sm-block ${mobilePopupOpen ? 'open' : ''}`}>
                                    <li>
                                        <NavLink
                                            to="/professions"
                                            className="nav-link"
                                        >
                                            <MenuProfessionIcon width="32" height="32" />
                                            <span>{t('navigationBar:professions')}</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/professions-guide"
                                            className="nav-link"
                                        >
                                            <MenuProfessionGuideIcon width="27" height="27" />
                                            <span>{t('navigationBar:professionGuide')}</span>
                                            <span>{t('navigationBar:guide')}</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/learning"
                                            className="nav-link"
                                        >
                                            <MenuLearningIcon width="30" height="30" />
                                            <span>{t('navigationBar:learning')}</span>
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink
                                            to="/study-guide"
                                            className="nav-link"
                                        >
                                            <MenuStudyGuideIcon width="30" height="30" />
                                            <span>{t('navigationBar:studyGuide')}</span>
                                            <span>{t('navigationBar:guide')}</span>
                                        </NavLink>
                                    </li> */}
                                </div>
                            </ul>
                        </nav>
                    </aside>
                </Col>
            </Row>
        </Container>
    );
}

export default NavigationBar;
