import AttributeCategorySelection from "@/components/attribute-category-selection/AttributeCategorySelection";
import AnchorButton from "@/components/button/AnchorButton";
import CTAFullWidth from "@/components/cta/CTAFullWidth";
import ScrollToTopButton from "@/components/scroll-to-top/ScrollToTopButton";
import SwiperLockedComponent from "@/components/swiper-locked-component/swiper-locked-component";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import useFavoriteProfessions from "@/hooks/useFavoriteProfessions";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import useRecommendedProfessions from "@/hooks/useRecommendedProfessions";
import PageHeader from "@/layout/page-header/PageHeader";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { t } from "i18next";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './ProfessionsOverview.scss';
import FavoriteProfessions from "./components/FavoriteProfessions";
import ProfessionCategories from "./components/ProfessionCategories";
import ProfessionList from "./components/ProfessionList";
import RecommendedProfessions from "./components/RecommendedProfessions";

function ProfessionsOverview() {

    useDocumentTitle(t('documentTitles:professions'));

    const { favoriteProfessions } = useFavoriteProfessions();
    const { recommendedProfessions, mutate } = useRecommendedProfessions();
    const { totalCompletion } = useProfileCompletionRequirements();

    useEffect(() => {
        mutate();
    }, []);

    return (

        <div className="profession-overview">
            <UnauthenticatedTemplate>
                <PageHeader image='/assets/img/WIHV_3D_Visual_Groen_Wit.jpg' lightTheme={true}>
                    <Row className='position-relative py-5'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <h1 className='font-heavy text-geel pb-2'>{t('professions:overview:headerTitle')}</h1>
                            <h2 className="font-bold text-wit h3 pb-4">{t('professions:overview:headerSubtitle')}</h2>
                            <p className="text-wit">{t('professions:overview:headerDescription1')}</p>
                            <p className="text-wit">{t('professions:overview:headerDescription2')}</p>
                            <div className='d-flex pt-5'>
                                <AnchorButton linkToElement='#list' text={t('professions:overview:headerCtaBtn')} icon="chevron-right" />
                            </div>
                        </Col>
                    </Row>
                </PageHeader>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <PageHeader image='/assets/img/WIHV_3D_Visual_Groen_Wit.jpg' lightTheme={true}>
                    <Row className='position-relative py-5'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <h1 className='font-heavy text-geel pb-2'>{t('professions:overview:headerTitleProfile')}</h1>
                            <h2 className="font-bold text-wit h3 pb-4">{t('professions:overview:headerSubtitleProfile')}</h2>
                            <p className="text-wit">{t('professions:overview:headerDescriptionProfile1')}</p>
                            <p className="text-wit">{t('professions:overview:headerDescriptionProfile2')}</p>
                            <div className='d-flex pt-4'>
                                <AnchorButton linkToElement='#list' text={t('professions:overview:headerCtaProfileBtn')} icon="chevron-right" />
                            </div>
                        </Col>
                    </Row>
                </PageHeader>
            </AuthenticatedTemplate>

            <RecommendedProfessions />

            <FavoriteProfessions />

            <Container fluid className="bg-blauw py-5">
                <Container>
                    <ProfessionCategories />
                </Container>
            </Container>

            <Container fluid className="gradient-background py-5">
                <Container>
                    <CTAFullWidth
                        title={t('professions:overview:ctaProfileTitle')}
                        description={t('professions:overview:ctaProfileDescription')}
                        imageSrc={'/assets/img/professions/WIHV_Professions_Helpdesk.jpg'}
                        buttonText={t('professions:overview:ctaProfileBtn')}
                        buttonLink={'/profile'} />
                </Container>
            </Container>

            <Container fluid className="bg-lichtblauw py-5">
                <Container>
                    <ProfessionList />
                </Container>
            </Container>

            <ScrollToTopButton section='list' />
        </div>
    )
}

export default ProfessionsOverview