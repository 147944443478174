import LinkButton from "@/components/button/LinkButton";
import SwiperLockedComponent from "@/components/swiper-locked-component/swiper-locked-component";
import useRecommendedLearningResources from "@/hooks/useRecommendedLearningResources";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { t } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import LearningResourceSwiper from "./LearningResourceSwiper";

function RecommendedLearningResources() {

    const { recommendedLearningResources, isLoading } = useRecommendedLearningResources();

    return (
        <>
            <AuthenticatedTemplate>
                {
                    recommendedLearningResources?.length > 0 &&
                    <Container fluid className="bg-lila py-5">
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <div className="pb-3 pt-3">
                                        <h2>{t('learning:overview:recommendedTitle')}</h2>
                                    </div>

                                    <div>
                                        <LearningResourceSwiper resources={recommendedLearningResources} />
                                        {/* TODO: add skeleton loader */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                }
            </AuthenticatedTemplate>
            
            <UnauthenticatedTemplate>
                <Container fluid className="bg-lila py-5">
                    <Container>
                        <Row>
                            <Col>
                                <div className="pb-3 pt-3">
                                    <h2>{t('learning:overview:recommendedTitle')}</h2>
                                </div>
                                <SwiperLockedComponent>
                                    <h2 className="pb-2">{t('learning:overview:recommendedLockedTitle')}</h2>
                                    <p className="pb-3">{t('learning:overview:recommendedLockedDescription')}</p>
                                    <LinkButton to={'/profile'} text={t('learning:overview:recommendedLockedBtn')} icon='chevron-right' />
                                </SwiperLockedComponent>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </UnauthenticatedTemplate>
        </>
    )
}

export default RecommendedLearningResources