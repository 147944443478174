
import useFavoriteLearningResources from '@/hooks/useFavoriteLearningResources';
import { t } from 'i18next';
import LearningResourceSwiper from './LearningResourceSwiper';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Container } from 'react-bootstrap';

function FavoriteLearningResources() {

    const { isLoading, favoriteLearningResources } = useFavoriteLearningResources();

    return (
        <AuthenticatedTemplate>
            {
                favoriteLearningResources?.length > 0 &&
                <Container fluid className="bg-lichtblauw py-5 position-relative">
                    <div className='colored-background'></div>
                    <Container className="position-relative">
                        <div>
                            <h2 className="d-flex align-items-center pb-3">
                                <span>{t('learning:overview:favoritesTitle')}</span><i className='fas fa-star fa-xs ms-2' />
                            </h2>
                            <LearningResourceSwiper resources={favoriteLearningResources} />
                        </div>
                    </Container>
                </Container>
            }
        </AuthenticatedTemplate>
    )
}

export default FavoriteLearningResources