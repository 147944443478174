import StepperComponent, { Step } from "@/components/stepper/Stepper";
import UnsavedChangesGuard from "@/components/unsaved-changes-guard/UnsavedChangesGuard";
import { Evaluation } from "@/development/models/evaluation";
import { useAttributeCategoryTranslation } from "@/general/i18n/translation-helpers";
import { AttributeCategory } from "@/general/model/attribute-category";
import { AttributeFeedback } from "@/general/model/attribute-feedback";
import { t } from "i18next";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import './AttributeEvaluation.scss';

type AttributeEvaluationProps = {
    evaluation: Evaluation;
    isSubmitting: boolean;
    onSave: (feedbacks: AttributeFeedback[]) => void;
}

function AttributeEvaluation({ evaluation, isSubmitting, onSave }: AttributeEvaluationProps) {

    const { translateAttributeCategory } = useAttributeCategoryTranslation();
    const [feedbacks, setFeedbacks] = useState<AttributeFeedback[]>(evaluation?.attributeFeedbacks);

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [steps, setSteps] = useState<Step[]>([
        {
            stepValue: AttributeCategory.Personality,
            stepLabel: translateAttributeCategory(AttributeCategory.Personality),
            completed: false
        },
        {
            stepValue: AttributeCategory.Skills,
            stepLabel: translateAttributeCategory(AttributeCategory.Skills),
            completed: false
        },
        {
            stepValue: AttributeCategory.Potential,
            stepLabel: translateAttributeCategory(AttributeCategory.Potential),
            completed: false
        }
    ]);

    function handlePreviousBtn() {
        const updatedSteps = steps.map((step, index) => {
            if (index === currentStepIndex) {
                return { ...step, completed: true };
            }
            return step;
        });
        setSteps(updatedSteps);
        setCurrentStepIndex(currentStepIndex - 1);

        const button = document.querySelectorAll('button');
        button.forEach((button) => {
            button.blur();
        });
    }

    function handleNextBtn() {
        const updatedSteps = steps.map((step, index) => {
            if (index === currentStepIndex) {
                return { ...step, completed: true };
            }
            return step;
        });
        setSteps(updatedSteps);
        setCurrentStepIndex(currentStepIndex + 1);

        const button = document.querySelectorAll('button');
        button.forEach((button) => {
            button.blur();
        });
    }

    function handleStepClick(index: number) {

        if (index > currentStepIndex + 1) {
            return;
        }
        const updatedSteps = steps.map((step, i) => {
            if (i === currentStepIndex) {
                return { ...step, completed: true };
            }
            return step;
        });
        setSteps(updatedSteps);
        setCurrentStepIndex(index);
    }

    const handleFeedbackChange = (attributeId, value) => {

        const updatedFeedbacks = feedbacks.map((feedback) => {
            if (feedback.attributeId === attributeId) {
                return { ...feedback, feedback: value };
            }
            return feedback;
        });

        setFeedbacks(updatedFeedbacks);

        const buttons = document.querySelectorAll(`button[data-attribute-id="${attributeId}"]`);
        buttons.forEach((button) => {
            const feedbackValue = button.getAttribute('data-feedback-value');
            if (feedbackValue === String(value)) {
                button.classList.add('option--selected');
            } else {
                button.classList.remove('option--selected');
            }
        });
    };

    return (
        <div className="attribute-reflection py-5">
            <Row>
                <Col sm={12} md={12} lg={{ span: 10, offset: 1 }} className="attribute-reflection-card">

                    <StepperComponent steps={steps} currentStepIndex={currentStepIndex} stepClickEnabled={true} onHandleStepClick={handleStepClick} />

                    <div className='attribute-list'>

                        <div className="list-header">
                            <span className="">Kenmerk</span>
                            <span className="">oneens</span>
                            <span className="">neutraal</span>
                            <span className="">eens</span>
                        </div>

                        {feedbacks?.filter(f => f.attributeCategory == steps[currentStepIndex]?.stepValue).map((feedback, i) =>
                            <div className="list-item" key={i}>
                                <span className="attribute-name">{feedback.attributeName}</span>
                                <button
                                    data-attribute-id={feedback.attributeId}
                                    data-feedback-value={-1}
                                    className={`feedback-option ${feedback.feedback === -1 ? 'feedback-option--selected' : ''}`}
                                    onClick={() => handleFeedbackChange(feedback.attributeId, -1)} />
                                <button
                                    data-attribute-id={feedback.attributeId}
                                    data-feedback-value={0}
                                    className={`feedback-option ${feedback.feedback === 0 ? 'feedback-option--selected' : ''}`}
                                    onClick={() => handleFeedbackChange(feedback.attributeId, 0)} />
                                <button
                                    data-attribute-id={feedback.attributeId}
                                    data-feedback-value={1}
                                    className={`feedback-option ${feedback.feedback === 1 ? 'feedback-option--selected' : ''}`}
                                    onClick={() => handleFeedbackChange(feedback.attributeId, 1)} />
                            </div>
                        )}
                    </div>

                    <div className="actions">
                        {currentStepIndex > 0 &&
                            <button type='button' className='prev btn btn--zwartblauw btn--transparent' onClick={() => handlePreviousBtn()}>
                                <i className="fas fa-arrow-left me-2" />
                                {t('general:previous')}
                            </button>
                        }
                        {currentStepIndex < steps.length - 1 &&
                            <button type='button' className='next btn btn--zwartblauw btn--transparent' onClick={() => handleNextBtn()}>
                                {t('general:next')}
                                <i className="fas fa-arrow-right ms-2" />
                            </button>
                        }
                    </div>

                    {steps.every((step, index) => step.completed || index == steps.length - 1) &&
                        <div className="complete-action">
                            <button type='button' className='btn btn--oranje' disabled={isSubmitting} onClick={() => onSave(feedbacks)}>
                                {t('development:reflection:saveBtn')}
                                {isSubmitting
                                    ? <i className="fa-solid fa-spinner fa-lg fa-spin ms-2" />
                                    : <i className="fas fa-floppy-disk ms-2" />}
                            </button>
                        </div>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default AttributeEvaluation