import useStartedLearningCourses from '@/hooks/useStartedLearningCourses';
import useStartedLearningPaths from '@/hooks/useStartedLearningPaths';
import { t } from 'i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import './CurrentlyLearningTile.scss';
import DashboardTile from './DashboardTile';

type CurrentlyLearningTileProps = {
    tileClassName?: string;
}

function CurrentlyLearningTile({ tileClassName }: CurrentlyLearningTileProps) {

    const { learningCourses, learningCoursesLoading } = useStartedLearningCourses();
    const { learningPaths, learningPathsLoading } = useStartedLearningPaths();
    const hasLearningItems = learningCourses?.length > 0 || learningPaths?.length > 0;

    return (
        <DashboardTile tileClassName={tileClassName} contentClassName='currently-learning-tile-content'>
            <h2 className='title'>{t('dashboard:currentlyLearning:title')}</h2>
            <div className='learning-list'>

                <h3 className='subtitle'>
                    <i className="fas fa-graduation-cap me-2"></i>
                    {t('dashboard:currentlyLearning:courses')}
                </h3>

                {learningCourses?.length > 0 && learningCourses?.slice(0, 2).map(course => (
                    <div key={course.id} className='learning-item'>
                        <span className='name'>{course.name}</span>
                        <Link to={`learning/course/${course.id}`} className='link'>
                            {t('dashboard:currentlyLearning:continueLearning')}
                        </Link>
                    </div>
                ))}

                {learningCoursesLoading &&
                    <Skeleton count={2} inline={true} height={24} className='learning-item mb-2' />
                }

                <h3 className='subtitle'>
                    <i className="fas fa-sitemap me-2"></i>
                    {t('dashboard:currentlyLearning:learningPaths')}
                </h3>

                {learningPaths?.length > 0 && learningPaths?.slice(0, 2).map(path => (
                    <div key={path.id} className='learning-item'>
                        <span className='name'>{path.name}</span>
                        <Link to={`learning/path/${path.id}`} className='link'>
                            {t('dashboard:currentlyLearning:continueLearning')}
                        </Link>
                    </div>
                ))}

                {learningPathsLoading &&
                    <Skeleton count={2} inline={true} height={24} className='learning-item mb-2' />
                }
            </div>

            {!(learningCoursesLoading || learningPathsLoading) && !hasLearningItems &&
                <div className='no-learning-items'>
                    <p className='description'>
                        {t('dashboard:currentlyLearning:noLearningItems')}
                    </p>
                    <Link to='/learning' className='link btn btn--oranje'>
                        {t('dashboard:currentlyLearning:cta')}
                        <i className="fas fa-arrow-right ms-2"></i>
                    </Link>
                </div>
            }
        </DashboardTile>
    )
}

export default CurrentlyLearningTile