import { Attribute } from '@/general/model/attribute'
import './AttributesResult.scss'
import Skeleton from 'react-loading-skeleton'

type AttributesResultProps = {
    attributes: Attribute[]
}

function AttributesResult({ attributes }: AttributesResultProps) {
    return (
        <div className='attributes-result-wrapper'>
            <div className='attributes-result'>
                {attributes &&
                    attributes.map((attribute, i) => (
                        <div key={attribute.id} className='attribute'>
                            <h3 className='title'>{attribute.name}</h3>
                            <p className='description'>{attribute.description}</p>
                        </div>
                    ))
                }

                {
                    !attributes &&
                    <Skeleton containerClassName='d-flex gap-4' count={3} height={140} width={'15vw'} style={{ opacity: 0.7 }}/>
                }
            </div>
        </div>
    )
}

export default AttributesResult