export const MenuDashboardIcon = (props) => (
    <svg id="fi-rr-apps" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.994 33">
        <path id="Path_3165" data-name="Path 3165" d="M9.331,0h-4A5.332,5.332,0,0,0,0,5.332V12A5.332,5.332,0,0,0,5.332,17.33h4A5.332,5.332,0,0,0,14.664,12V5.332A5.332,5.332,0,0,0,9.331,0ZM12,12a2.666,2.666,0,0,1-2.666,2.666h-4A2.666,2.666,0,0,1,2.666,12V5.332A2.666,2.666,0,0,1,5.332,2.666h4A2.666,2.666,0,0,1,12,5.332Z" transform="translate(0 0)" />
        <path id="Path_3166" data-name="Path 3166" d="M22.331,0h-4A5.332,5.332,0,0,0,13,5.332V6.665A5.332,5.332,0,0,0,18.332,12h4a5.332,5.332,0,0,0,5.332-5.332V5.332A5.332,5.332,0,0,0,22.331,0ZM25,6.665a2.666,2.666,0,0,1-2.666,2.666h-4a2.666,2.666,0,0,1-2.666-2.666V5.332a2.666,2.666,0,0,1,2.666-2.666h4A2.666,2.666,0,0,1,25,5.332Z" transform="translate(4.33 0)" />
        <path id="Path_3167" data-name="Path 3167" d="M9.331,15h-4A5.332,5.332,0,0,0,0,20.332v1.333A5.332,5.332,0,0,0,5.332,27h4a5.332,5.332,0,0,0,5.332-5.332V20.332A5.332,5.332,0,0,0,9.331,15ZM12,21.665a2.666,2.666,0,0,1-2.666,2.666h-4a2.666,2.666,0,0,1-2.666-2.666V20.332a2.666,2.666,0,0,1,2.666-2.666h4A2.666,2.666,0,0,1,12,20.332Z" transform="translate(0 4.996)" />
        <path id="Path_3168" data-name="Path 3168" d="M22.331,11h-4A5.332,5.332,0,0,0,13,16.332V23a5.332,5.332,0,0,0,5.332,5.332h4A5.332,5.332,0,0,0,27.664,23V16.332A5.332,5.332,0,0,0,22.331,11ZM25,23a2.666,2.666,0,0,1-2.666,2.666h-4A2.666,2.666,0,0,1,15.666,23V16.332a2.666,2.666,0,0,1,2.666-2.666h4A2.666,2.666,0,0,1,25,16.332Z" transform="translate(4.33 3.664)" />
    </svg>
)

export const MenuProfileIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.951 31.951">
        <path id="fi-rr-face-smile-upside-down" d="M15.976,0A15.976,15.976,0,1,0,31.951,15.976,15.993,15.993,0,0,0,15.976,0Zm0,29.289A13.313,13.313,0,1,1,29.289,15.976,13.328,13.328,0,0,1,15.976,29.289Zm7.543-18.3a1.331,1.331,0,0,1-1.773,1.986,10,10,0,0,0-5.77-2.324A10,10,0,0,0,10.2,12.978,1.331,1.331,0,0,1,8.432,10.99a12.586,12.586,0,0,1,7.543-3,12.585,12.585,0,0,1,7.543,3ZM22.632,19.3a2,2,0,1,1-2-2A2,2,0,0,1,22.632,19.3Zm-9.319,0a2,2,0,1,1-2-2A2,2,0,0,1,13.313,19.3Z" transform="translate(31.951 31.951) rotate(180)" />
    </svg>
)

export const MenuMeasurementIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.768 31.768">
        <path id="fi-rr-face-viewfinder" d="M17.208,13.9a1.985,1.985,0,1,1,1.985,1.985A1.986,1.986,0,0,1,17.208,13.9Zm-4.633,1.985A1.985,1.985,0,1,0,10.589,13.9,1.986,1.986,0,0,0,12.575,15.884Zm3.309,10.589A10.589,10.589,0,1,1,26.473,15.884,10.6,10.6,0,0,1,15.884,26.473Zm7.942-10.589a7.942,7.942,0,1,0-7.942,7.942A7.95,7.95,0,0,0,23.826,15.884ZM19.12,17.43a7.11,7.11,0,0,1-3.236,1.1,7,7,0,0,1-3.239-1.1,1.324,1.324,0,0,0-1.467,2.205,9.525,9.525,0,0,0,4.706,1.546,9.525,9.525,0,0,0,4.706-1.546,1.324,1.324,0,1,0-1.469-2.2ZM9.266,29.121H6.618A3.976,3.976,0,0,1,2.647,25.15V22.5A1.324,1.324,0,0,0,0,22.5V25.15a6.626,6.626,0,0,0,6.618,6.618H9.266a1.324,1.324,0,1,0,0-2.647Zm21.179-7.942A1.323,1.323,0,0,0,29.121,22.5V25.15a3.976,3.976,0,0,1-3.971,3.971H22.5a1.324,1.324,0,1,0,0,2.647H25.15a6.626,6.626,0,0,0,6.618-6.618V22.5A1.323,1.323,0,0,0,30.444,21.179ZM25.15,0H22.5a1.324,1.324,0,0,0,0,2.647H25.15a3.976,3.976,0,0,1,3.971,3.971V9.266a1.324,1.324,0,1,0,2.647,0V6.618A6.626,6.626,0,0,0,25.15,0ZM1.324,10.589A1.323,1.323,0,0,0,2.647,9.266V6.618A3.976,3.976,0,0,1,6.618,2.647H9.266A1.324,1.324,0,1,0,9.266,0H6.618A6.626,6.626,0,0,0,0,6.618V9.266A1.323,1.323,0,0,0,1.324,10.589Z" />
    </svg>
)

export const MenuProfessionIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.924 23.943">
        <path id="fi-rr-laptop-code" d="M10.2,15.171a2.984,2.984,0,0,1-.02-4.225L12.417,8.71A1.33,1.33,0,1,1,14.3,10.591l-2.236,2.236a.313.313,0,0,0,0,.443l2.16,2.071a1.331,1.331,0,0,1-1.842,1.921Zm9.66-2.344a.309.309,0,0,1,.02.423L17.7,15.341a1.331,1.331,0,0,0,1.842,1.921l2.2-2.11a2.975,2.975,0,0,0,0-4.2L19.507,8.712a1.33,1.33,0,0,0-1.881,1.881l2.236,2.236ZM31.924,22.953a4,4,0,0,1-3.991,3.991H3.991a3.99,3.99,0,0,1-1.33-7.752V9.651A6.658,6.658,0,0,1,9.311,3h13.3a6.658,6.658,0,0,1,6.651,6.651v9.54A4,4,0,0,1,31.924,22.953Zm-26.6-13.3v9.311h5.986a1.327,1.327,0,0,1,.94.39l.94.94h5.549l.94-.94a1.329,1.329,0,0,1,.94-.39H26.6V9.651A4,4,0,0,0,22.613,5.66H9.311A4,4,0,0,0,5.321,9.651Zm23.943,13.3a1.332,1.332,0,0,0-1.33-1.33H21.168l-.94.94a1.329,1.329,0,0,1-.94.39H12.637a1.327,1.327,0,0,1-.94-.39l-.94-.94H3.991a1.33,1.33,0,1,0,0,2.66H27.934A1.332,1.332,0,0,0,29.264,22.953Z" />
    </svg>
)

export const MenuProfessionGuideIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 27.764 27.764">
        <path id="fi-rr-glasses" d="M23.136,0a4.627,4.627,0,0,0-4.627,4.627,1.157,1.157,0,0,0,2.314,0,2.314,2.314,0,1,1,4.627,0V17.382a5.724,5.724,0,0,0-3.47-1.187,5.784,5.784,0,0,0-4.795,2.553,4.6,4.6,0,0,0-6.605,0A5.784,5.784,0,0,0,5.784,16.2a5.724,5.724,0,0,0-3.47,1.187V4.627a2.314,2.314,0,0,1,4.627,0,1.157,1.157,0,0,0,2.314,0A4.627,4.627,0,1,0,0,4.627V21.979a5.784,5.784,0,0,0,11.568,0,2.314,2.314,0,1,1,4.627,0,5.784,5.784,0,1,0,11.568,0V4.627A4.627,4.627,0,0,0,23.136,0ZM5.784,25.45a3.47,3.47,0,1,1,3.47-3.47A3.47,3.47,0,0,1,5.784,25.45Zm16.2,0a3.47,3.47,0,1,1,3.47-3.47A3.47,3.47,0,0,1,21.979,25.45Z" />
    </svg>
)

export const MenuLearningIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.817 31.817">
        <path id="fi-rr-brain" d="M31.817,14.583a6.612,6.612,0,0,0-2.651-5.276,5.347,5.347,0,0,0-4.11-5.2A5.3,5.3,0,0,0,15.909,1.8,5.3,5.3,0,0,0,6.761,4.11a5.347,5.347,0,0,0-4.11,5.2A6.612,6.612,0,0,0,0,14.583,6.463,6.463,0,0,0,.915,17.9,6.463,6.463,0,0,0,0,21.212,6.617,6.617,0,0,0,3.009,26.74a7.279,7.279,0,0,0,12.9,1.975,7.279,7.279,0,0,0,12.9-1.975,6.6,6.6,0,0,0,3.009-5.528A6.463,6.463,0,0,0,30.9,17.9,6.463,6.463,0,0,0,31.817,14.583ZM14.583,24.526a4.633,4.633,0,0,1-9.147,1.047,1.342,1.342,0,0,0-.663-.875,3.992,3.992,0,0,1-2.121-3.487,3.886,3.886,0,0,1,.9-2.479,1.318,1.318,0,0,0,0-1.67,3.9,3.9,0,0,1,1.14-5.926,1.318,1.318,0,0,0,.65-1.392A2.615,2.615,0,0,1,5.29,9.28,2.659,2.659,0,0,1,7.941,6.629,1.33,1.33,0,0,0,9.267,5.3a2.651,2.651,0,1,1,5.3,0V24.526Zm13.681-5.793a3.9,3.9,0,0,1-1.22,5.966,1.342,1.342,0,0,0-.663.875,4.633,4.633,0,0,1-9.147-1.047V5.3a2.651,2.651,0,1,1,5.3,0,1.33,1.33,0,0,0,1.326,1.326A2.659,2.659,0,0,1,26.514,9.28a2.615,2.615,0,0,1-.053.464,1.318,1.318,0,0,0,.65,1.392,3.981,3.981,0,0,1,2.042,3.447,3.86,3.86,0,0,1-.9,2.479,1.318,1.318,0,0,0,0,1.67Z" />
    </svg>
)

export const MenuStudyGuideIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 24 24">
        <path d="M17,0H7C4.243,0,2,2.243,2,5v15c0,2.206,1.794,4,4,4h11c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm3,5v11H8V2h4V10.347c0,.623,.791,.89,1.169,.395l1.331-1.743,1.331,1.743c.378,.495,1.169,.228,1.169-.395V2c1.654,0,3,1.346,3,3ZM6,2.184v13.816c-.732,0-1.409,.212-2,.556V5c0-1.302,.839-2.402,2-2.816Zm11,19.816H6c-2.629-.047-2.627-3.954,0-4h14v1c0,1.654-1.346,3-3,3Z"/>
    </svg>
)

export const MenuSettingsIcon = (props) => (
    <svg id="fi-rr-settings" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 30.2 33.564">
        <path id="Path_3181" data-name="Path 3181" d="M13.594,8a5.594,5.594,0,1,0,5.594,5.594A5.594,5.594,0,0,0,13.594,8Zm0,8.391a2.8,2.8,0,1,1,2.8-2.8A2.8,2.8,0,0,1,13.594,16.391Z" transform="translate(1.506 3.188)" />
        <path id="Path_3182" data-name="Path 3182" d="M29.3,19.439l-.621-.358a12.726,12.726,0,0,0,0-4.6l.621-.358a4.2,4.2,0,1,0-4.2-7.272l-.622.359a12.554,12.554,0,0,0-3.984-2.3V4.2a4.2,4.2,0,0,0-8.391,0v.717a12.554,12.554,0,0,0-3.983,2.3L7.5,6.853a4.2,4.2,0,1,0-4.2,7.272l.621.358a12.726,12.726,0,0,0,0,4.6l-.621.358a4.2,4.2,0,0,0,4.2,7.272l.622-.359a12.554,12.554,0,0,0,3.986,2.3v.717a4.2,4.2,0,0,0,8.391,0v-.717a12.555,12.555,0,0,0,3.983-2.3l.625.361a4.2,4.2,0,1,0,4.2-7.272Zm-3.563-5.281a9.857,9.857,0,0,1,0,5.244,1.4,1.4,0,0,0,.649,1.585l1.516.875a1.4,1.4,0,1,1-1.4,2.424l-1.519-.878a1.4,1.4,0,0,0-1.7.231,9.767,9.767,0,0,1-4.535,2.622,1.4,1.4,0,0,0-1.05,1.355v1.752a1.4,1.4,0,0,1-2.8,0V27.618a1.4,1.4,0,0,0-1.05-1.355,9.767,9.767,0,0,1-4.534-2.628,1.4,1.4,0,0,0-1.7-.231L6.1,24.281a1.4,1.4,0,1,1-1.4-2.422l1.516-.875A1.4,1.4,0,0,0,6.87,19.4a9.857,9.857,0,0,1,0-5.244,1.4,1.4,0,0,0-.65-1.579L4.7,11.7A1.4,1.4,0,0,1,6.1,9.276l1.519.878a1.4,1.4,0,0,0,1.7-.225,9.767,9.767,0,0,1,4.535-2.622A1.4,1.4,0,0,0,14.9,5.946V4.2a1.4,1.4,0,1,1,2.8,0V5.946A1.4,1.4,0,0,0,18.752,7.3a9.767,9.767,0,0,1,4.535,2.628,1.4,1.4,0,0,0,1.7.231L26.5,9.283a1.4,1.4,0,1,1,1.4,2.422l-1.516.875a1.4,1.4,0,0,0-.648,1.579Z" transform="translate(-1.203)" />
    </svg>
)

export const MenuLogoutIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 24.994 25">
        <g id="fi-rr-exit" transform="translate(0.5 0.5)" opacity="0.503">
            <path id="Path_3281" data-name="Path 3281" d="M22.829,9.172,18.95,5.293a1,1,0,1,0-1.414,1.414l3.879,3.879a2.057,2.057,0,0,1,.3.39c-.015,0-.027-.008-.042-.008h0L5.989,11a1,1,0,0,0,0,2h0l15.678-.032c.028,0,.051-.014.078-.016a2,2,0,0,1-.334.462l-3.879,3.879a1,1,0,1,0,1.414,1.414l3.879-3.879a4,4,0,0,0,0-5.656Z" fill="#0c1732" />
            <path id="Path_3281_-_Outline" data-name="Path 3281 - Outline" d="M18.243,4.5a1.49,1.49,0,0,1,1.061.44l4.732,4.732h-.188a4.508,4.508,0,0,1-.669,5.51L19.3,19.061a1.5,1.5,0,1,1-2.121-2.121l3.469-3.469L5.99,13.5a1.5,1.5,0,1,1,0-3l14.6-.03-3.41-3.41A1.5,1.5,0,0,1,18.243,4.5Zm3.379,4.172L18.6,5.647a.5.5,0,1,0-.707.707l3.879,3.879a2.569,2.569,0,0,1,.376.488l.448.755h-.878a.545.545,0,0,1-.093-.008L5.99,11.5a.5.5,0,1,0,0,1l15.624-.032a.611.611,0,0,1,.095-.015l.9-.067-.418.8a2.5,2.5,0,0,1-.418.578l-.006.006-3.879,3.879a.5.5,0,1,0,.707.707l3.879-3.879a3.5,3.5,0,0,0,0-4.949l-.853-.854Z" fill="#0c1732" />
            <path id="Path_3282" data-name="Path 3282" d="M7,22H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H7A1,1,0,1,0,7,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H7a1,1,0,0,0,0-2Z" fill="#0c1732" />
            <path id="Path_3282_-_Outline" data-name="Path 3282 - Outline" d="M7,24.5H5A5.515,5.515,0,0,1-.5,19V5A5.515,5.515,0,0,1,5-.5H7a1.5,1.5,0,0,1,0,3H5A2.5,2.5,0,0,0,2.5,5V19A2.5,2.5,0,0,0,5,21.5H7a1.5,1.5,0,0,1,0,3ZM5,.5A4.513,4.513,0,0,0,.5,5V19A4.513,4.513,0,0,0,5,23.5H7a.5.5,0,0,0,0-1H5A3.5,3.5,0,0,1,1.5,19V5A3.5,3.5,0,0,1,5,1.5H7a.5.5,0,0,0,0-1Z" fill="#0c1732" />
        </g>
    </svg>
)

export const MenuMoreIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 27.42 6.76">
        <path id="Path_3350" data-name="Path 3350" d="M10.331,23.97a3.38,3.38,0,1,0,0,6.76,3.38,3.38,0,0,0,0-6.76Zm10.33,0a3.38,3.38,0,1,0,3.364,3.349A3.433,3.433,0,0,0,20.661,23.97Zm10.33,0a3.38,3.38,0,1,0,3.364,3.349A3.433,3.433,0,0,0,30.991,23.97Z" transform="translate(-6.935 -23.97)" />
    </svg>
)
