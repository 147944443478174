import CTAFullWidth from "@/components/cta/CTAFullWidth";
import CreateProfileTile from "@/dashboard/components/CreateProfileTile";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import useRecommendedProfessions from "@/hooks/useRecommendedProfessions";
import PageHeader from "@/layout/page-header/PageHeader";
import { useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './ProfessionsGuideOverview.scss';
import RecommendedProfessionsPodium from "./components/RecommendedProfessionsPodium";
import RecommendedProfessionsTable from "./components/RecommendedProfessionsTable";

function ProfessionsGuideOverview() {

    useDocumentTitle(t('documentTitles:professionGuide'));

    const isAuthenticated = useIsAuthenticated();
    const { recommendedProfessions, mutate } = useRecommendedProfessions();
    const { profileCompletionRequirements, profileCompleted, isLoading } = useProfileCompletionRequirements();

    useEffect(() => {
        mutate();
    }, []);

    useEffect(() => {
        if (recommendedProfessions) {
            recommendedProfessions.sort((a, b) => b.percentRecommended - a.percentRecommended);
        }
    }, [recommendedProfessions]);

    return (
        <div className="professions-guide">
            <PageHeader image='/assets/img/WIHV_3D_Visual_Geel_Wit.jpg' lightTheme={false}>
                <Row className='position-relative py-5'>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <h1 className='font-heavy text-geel pb-2'>{t('professionsGuide:headerTitle')}</h1>
                        <h2 className="font-bold text-wit h3 pb-4">{t('professionsGuide:headerSubtitle')}</h2>
                        <p className="text-wit">{t('professionsGuide:headerDescription1')}</p>
                        <p className="text-wit">{t('professionsGuide:headerDescription2')}</p>
                    </Col>
                </Row>
            </PageHeader>

            {!isAuthenticated &&
                <Container fluid className='py-5 position-relative'>
                    <Container>
                        <Row>
                            <Col sm={{ span: 8, offset: 2 }}>
                                <CreateProfileTile />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }

            {isAuthenticated && !(profileCompleted || true) &&
                <Container fluid className='py-5 position-relative'>
                    <Container>
                        <CTAFullWidth
                            title="Vul je profiel aan"
                            description="We hebben nog niet genoeg informatie om je aanbevolen beroepen te tonen. Vul je profiel aan met de benodige informatie. Op je dashboard zie je de voortgang en welke taken je nog moet uitvoeren voor een volledig profiel. Succes!"
                            backgroundColor="bg-geel"
                            imageSrc="/assets/img/WIHV_3D_Visual_Profiel.jpg"
                            buttonLink="/profile"
                            buttonText="Naar profiel" />
                    </Container>
                </Container>
            }

            {/* TODO: Reset profilecompleted */}
            {isAuthenticated && (profileCompleted || true) && recommendedProfessions?.length > 0 &&
                <>
                    <Container fluid className='py-5 position-relative'>
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <RecommendedProfessionsPodium recommendedProfessions={recommendedProfessions.slice(0, 3)} />
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className='pb-5 position-relative'>
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <RecommendedProfessionsTable recommendedProfessions={recommendedProfessions.slice(3)} />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </>
            }
        </div>
    )
}

export default ProfessionsGuideOverview