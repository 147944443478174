import PageNotFound from '@/components/not-found-component/PageNotFound';
import DashboardOverview from '@/dashboard/DashboardOverview';
import DevelopmentModule from '@/development/DevelopmentModule';
import EvaluationModule from '@/evaluation/EvaluationModule';
import { PlatformSWRConfig } from '@/general/swr-config';
import LearningModule from '@/learning/LearningModule';
import MeasurementsModule from '@/measurements/MeasurementsModule';
import ProfessionsGuideOverview from '@/professions-guide/ProfessionsGuideOverview';
import ProfessionsModule from '@/professions/ProfessionsModule';
import ProfileModule from '@/profile/ProfileModule';
import SettingsModule from '@/settings/SettingsModule';
import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Layout from './app-layout';
import VouchersOverview from '@/vouchers/VouchersOverview';

const AppRouter = () => {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path='/*' element={
                <PlatformSWRConfig>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index path="/" element={<DashboardOverview />} />
                            <Route path="/profile/*" element={<ProfileModule />} />
                            <Route path="/development/*" element={<DevelopmentModule />} />
                            <Route path="/settings" element={<SettingsModule />} />
                            <Route path="/measurements/*" element={<MeasurementsModule />} />
                            <Route path="/professions/*" element={<ProfessionsModule />} />
                            <Route path="/professions-guide" element={<ProfessionsGuideOverview />} />
                            <Route path="/learning/*" element={<LearningModule />} />
                            <Route path='/evaluation/*' element={<EvaluationModule />} />
                            <Route path='/vouchers' element={<VouchersOverview />} />
                            <Route path="/not-found" element={<PageNotFound />} />
                            <Route path="*" element={<PageNotFound />} />
                        </Route>
                    </Routes>
                </PlatformSWRConfig>
            }>
            </Route>
        )
    );

    return (
        <RouterProvider router={router} />
    )
}

export default AppRouter;

