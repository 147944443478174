import { Col, Row } from "react-bootstrap"
import './CTAFullWidth.scss'
import { Link } from "react-router-dom";

type CTAFullWidthProps = {
    title: string;
    description: string;
    backgroundColor?: string;
    buttonText?: string;
    buttonLink?: string;
    imageSrc: string;
}

function CTAFullWidth({ title, description, backgroundColor = 'bg-geel', buttonText, buttonLink, imageSrc }: CTAFullWidthProps) {
    return (
        <Row>
            <Col sm={12}>
                <div className={`cta-full-card ${backgroundColor}`}>
                    <div className="background-image">
                        <img src={imageSrc} alt="" className="img-fluid d-none d-lg-block" />
                    </div>

                    <div className="content">
                        <h2 className="title">{title}</h2>
                        <p className="description">{description}</p>
                        {
                            buttonText && 
                            <Link className='button' to={buttonLink}>
                                {buttonText}
                                <i className="fas fa-chevron-right ms-2"></i>
                            </Link>
                        }
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default CTAFullWidth