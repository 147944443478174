import toast from 'react-hot-toast';
import LinkButton from '../components/button/LinkButton';
import { t } from 'i18next';

const useCustomToasts = () => {

    const showMeasurementsVoucherCTAToast = () => {
        toast(
            (ref) => (
                <div className="toast-content">
                    <p>
                        {/* TODO: translations */}
                        Je hebt momenteel nog geen toegang tot alle beschikbare testen. Vul een voucher in of vraag deze aan via de volgende link.
                    </p>
                    <LinkButton text="Mijn vouchers" icon="arrow-right" to={'/vouchers'} displayType="primary" onClick={() => toast.dismiss(ref.id)} />
                    <i role="button" className="fas fa-times fa-xl" onClick={() => toast.dismiss(ref.id)} />
                </div>
            ),
            {
                
                icon: <i className="fas fa-circle-info fa-lg"></i>,
                duration: 15000,
                className: 'toast-measurements-cta',
                style: {
                    backgroundColor: 'var(--color-purple-500)',
                    color: 'var(--color-neutral-light-0)',
                    borderRadius: 'var(--border-radius-round)',
                    maxWidth: '75%',                    
                },
            }
        );
    };

    return {
        showMeasurementsVoucherCTAToast,
    };
}

export default useCustomToasts;