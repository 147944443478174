import UnsavedChangesGuard from '@/components/unsaved-changes-guard/UnsavedChangesGuard';
import { ConfigContext } from '@/context/ConfigContext';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import PageHeader from '@/layout/page-header/PageHeader';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap';
import useSWR from 'swr';
import { SettingsContext } from '../../context/settings-context';
import './SettingsOverview.scss';
import AccountSettings from './components/AccountSettings';
import PrivacySettings from './components/PrivacySettings';

function SettingsOverview() {

    useDocumentTitle(t('documentTitles:settings'));

    const configContext = useContext(ConfigContext);
    const settingsContext = useContext(SettingsContext);

    const { data, isLoading, error } = useSWR(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.general.settings);

    useEffect(() => {

        if (data) {
            settingsContext.updateSettings(data);
        }

    }, [data]);

    return (
        <div className='settings'>

            <PageHeader image='/assets/img/WIHV_3D_Visual_Profiel_3.jpg' lightTheme={false}>

                <Row className='position-relative py-5 first-row-after-heading-img'>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} className='d-flex flex-column text-wit'>
                        <div className="font-heavy h2 pb-5">{t('settings:title')}</div>
                        <p className='flex-grow-1'>
                            {t('settings:description')}
                        </p>
                    </Col>
                </Row>

                <Row className='position-relative pb-5'>
                    <Col className='d-flex d-md-block justify-content-center'>
                        <ButtonGroup>
                            {/* {!settingsContext.editable &&
                                <Button className="btn btn--wit" onClick={() => settingsContext.updateEditable(true)}>
                                    <i className="fas fa-pen me-2"></i>
                                    <span>{t('settings:editSettingsBtn')}</span>
                                </Button>
                            } */}

                            {settingsContext.editable &&
                                <>
                                    <Button className="btn btn--zwartblauw" disabled={settingsContext.requestInProgress} onClick={() => settingsContext.updateEditable(false)}>
                                        <i className="fas fa-xmark me-2"></i>
                                        <span>{t('general:cancel')}</span>
                                    </Button>
                                    <Button className="btn btn--zwartblauw" disabled={settingsContext.requestInProgress} onClick={() => settingsContext.saveSettings(settingsContext.tempChanges)}>
                                        <i className="fas fa-floppy-disk me-2"></i>
                                        <span>{t('settings:saveSettingsBtn')}</span>
                                    </Button>
                                </>
                            }
                        </ButtonGroup>
                    </Col>
                </Row>
            </PageHeader>

            <Container fluid className='py-5'>
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <AccountSettings />
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <PrivacySettings />
                        </Col>
                    </Row>
                </Container>
            </Container>

            <UnsavedChangesGuard shouldBlock={{ obj1: settingsContext.settings, obj2: settingsContext.tempChanges }} />
        </div>
    )
}

export default SettingsOverview