import useInitialLanguage from '@/hooks/useInitialLanguage';
import AppRouter from '@/layout/app-router';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { t } from 'i18next';
import { PlatformSettingsProvider } from './context/PlatformSettingsProvider';
import { reactPlugin } from './general/application-insight';

interface IAppProps {
    msalInstance: PublicClientApplication;
}

export function App(props: IAppProps) {

    useInitialLanguage();

    return (
        <div>
            <AppInsightsErrorBoundary onError={() => <h1>{t('error_occurred')}</h1>} appInsights={reactPlugin}>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <MsalProvider instance={props.msalInstance}>
                        <PlatformSettingsProvider>
                            <AppRouter />
                        </PlatformSettingsProvider>
                    </MsalProvider>
                </AppInsightsContext.Provider>
            </AppInsightsErrorBoundary>
        </div>
    )
};

export default App;


