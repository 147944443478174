import LinkButton from "@/components/button/LinkButton";
import SwiperLockedComponent from "@/components/swiper-locked-component/swiper-locked-component";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import useRecommendedProfessions from "@/hooks/useRecommendedProfessions";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { t } from "i18next";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProfessionSwiper from "./ProfessionSwiper";

function RecommendedProfessions() {

    const { totalCompletion } = useProfileCompletionRequirements();
    const { recommendedProfessions, mutate } = useRecommendedProfessions();

    useEffect(() => {
        mutate();
    }, []);

    return (
        <>
            <AuthenticatedTemplate>
                {totalCompletion == 100 && recommendedProfessions?.length > 0 &&
                    <Container fluid className="bg-lila py-5">
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <div className="pb-3 pt-3">
                                        <h2>{t('professions:overview:recommendedTitle')}</h2>
                                    </div>

                                    <div>
                                        <ProfessionSwiper professions={recommendedProfessions} showIndex={true} />
                                        {/* TODO: add skeleton loader */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                }

                {totalCompletion < 100 &&
                    <Container fluid className="bg-lila py-5 position-relative">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="pb-3 pt-3 position-relative">
                                        <h2 className={`learning-overview__recommended-title`}>
                                            {t('professions:overview:recommendedTitle')}
                                        </h2>
                                    </div>
                                    <SwiperLockedComponent>
                                        <h3 className="text-donkerblauw font-bold pb-2">Maak je profiel compleet</h3>
                                        <p className="pb-3">
                                            Maak je profiel compleet om aanbevelingen te ontvangen voor beroepen die bij jou passen.
                                            Zo kunnen wij je helpen bij het vinden van een beroep dat bij jou past. Zie de profielcompleetheid als een soort checklist.
                                        </p>
                                        <LinkButton text='Ga naar je profiel' icon='chevron-right' to={'/profile'} />
                                    </SwiperLockedComponent>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                }
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Container fluid className="bg-lila py-5">
                    <Container>
                        <Row>
                            <Col>
                                <div className="pb-3 pt-3">
                                    <h2>{t('professions:overview:recommendedTitle')}</h2>
                                </div>
                                <SwiperLockedComponent>
                                    <h2 className="pb-2">{t('professions:overview:recommendedLockedTitle')}</h2>
                                    <p className="pb-3">{t('professions:overview:recommendedLockedDescription')}</p>
                                    <LinkButton to={'/profile'} text={t('professions:overview:recommendedLockedBtn')} icon='chevron-right' />
                                </SwiperLockedComponent>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </UnauthenticatedTemplate>
        </>
    )
}

export default RecommendedProfessions