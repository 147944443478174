import Button from '@/components/button/Button'
import LinkButton from '@/components/button/LinkButton'
import { ConfigContext } from '@/context/ConfigContext'
import { Evaluation } from '@/development/models/evaluation'
import { EvaluationType } from '@/development/models/evaluation-type'
import { AttributeCategory } from '@/general/model/attribute-category'
import { t } from 'i18next'
import { useContext, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import useSWR from 'swr'
import FeedbackStatusOverview from './FeedbackStatusOverview'
import InviteFeedbackProvidersModal from './InviteFeedbackProvidersModal'
import './ProfileEvaluation.scss'

type ProfileEvaluationProps = {
    attributeCategory: AttributeCategory
}

function ProfileEvaluation({ attributeCategory }: ProfileEvaluationProps) {

    const configContext = useContext(ConfigContext);
    const endpoint = configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.evaluations.list + `?type=${EvaluationType.Feedback}`;
    const { data: evaluations, isLoading, error, mutate } = useSWR<Evaluation[]>(endpoint);

    const [showFeedbackProvidersModal, setShowFeedbackProvidersModal] = useState(false);

    return (
        <div className='profile-evaluation'>
            <Row className='pb-5'>
                <Col sm={12} md={6} className='feedback'>
                    <h3 className="title">{t('development:evaluation:feedbackTitle')}</h3>
                    <p className='description'>{t('development:evaluation:feedbackDescription')}</p>
                    <Button text={t('development:evaluation:feedbackBtn')} displayType='secondary' icon='user-check' onClick={() => { setShowFeedbackProvidersModal(true) }} />

                    {!isLoading && evaluations?.length > 0 &&
                        <FeedbackStatusOverview evaluations={evaluations} onSuccess={() => mutate()} />
                    }
                </Col>
                <Col sm={12} md={{ span: 5, offset: 1 }} className='reflection mt-5 mt-md-0'>
                    <h3 className="title">{t('development:evaluation:reflectionTitle')}</h3>
                    <p className='description'>{t('development:evaluation:reflectionDescription')}</p>
                    <LinkButton text={t('development:evaluation:reflectionBtn')} displayType='secondary' icon='magnifying-glass' to={'/evaluation/reflection'} />
                </Col>
            </Row>

            <InviteFeedbackProvidersModal
                showModal={showFeedbackProvidersModal}
                onClose={() => setShowFeedbackProvidersModal(false)}
                onSuccess={() => { setShowFeedbackProvidersModal(false); mutate(); }}
            />
        </div>
    )
}

export default ProfileEvaluation