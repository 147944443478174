
import ScrollToTopOnNavigation from "@/components/scroll-to-top/ScrollToTopOnNavigation";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import './app-layout.scss';
import NavigationBar from "./navigation-bar/navigation-bar";
import Header from "./header/Header";

function Layout() {

    return (
        <div className="app-container">

            <ScrollToTopOnNavigation />
            <NavigationBar />
            <Header />
            
            <div className="main-container">
                <main>
                    <Outlet />
                </main>
            </div>

            <Toaster
                position="bottom-center"
                gutter={16}
                toastOptions={
                    {
                        duration: 5000,
                        style: {
                            padding: '16px',
                            gap: '12px',
                        },
                        success: {
                            duration: 3000,
                            style: {
                                border: '2px solid #41B883'
                            },
                        },
                        error: {
                            style: {
                                border: '2px solid #F56565'
                            },
                        },
                        loading: {
                            style: {
                                border: '2px solid #3182CE'
                            },
                        }
                    }
                }
            />
        </div>
    );
}

export default Layout;