import { Container, Row } from "react-bootstrap"
import './matrix-measurement.scss'
import { useState } from "react";

const testdefinition = {
    id: 0,
    type: 4,
    title: 'Matrix meting',
    description: 'Dit is een matrix meting',
    measurementContent: {
        columnDefinitions: [
            {
                id: 0,
                columns: [
                    {
                        title: 'Zeer mee oneens',
                        value: 0,
                    },
                    {
                        title: 'Mee oneens',
                        value: 1,
                    },
                    {
                        title: 'Neutraal',
                        value: 2,
                    },
                    {
                        title: 'Mee eens',
                        value: 3,
                    },
                    {
                        title: 'Zeer mee eens',
                        value: 4,
                    }
                ]
            }
        ],
        statements: [
            {
                id: 0,
                columnDefinitionId: 0,
                title: 'In hoeverre bent u het eens met de volgende stellingen?',
                rows: [
                    {
                        title: 'Ik ben een teamspeler',
                        id: 0,
                    },
                    {
                        title: 'Ik kan goed luisteren',
                        id: 1,
                    },
                    {
                        title: 'Ik ben een leider',
                        id: 2,
                    },
                    {
                        title: 'Ik ben een volger',
                        id: 3,
                    },
                    {
                        title: 'Ik ben een denker',
                        id: 4,
                    }
                ]
            },
            {
                id: 1,
                columnDefinitionId: 0,
                title: 'In hoeverre bent u het eens met de volgende stellingen?',
                rows: [
                    {
                        title: 'Ik ben een teamspeler',
                        id: 0,
                    },
                    {
                        title: 'Ik kan goed luisteren',
                        id: 1,
                    },
                    {
                        title: 'Ik ben een leider',
                        id: 2,
                    },
                    {
                        title: 'Ik ben een volger',
                        id: 3,
                    },
                    {
                        title: 'Ik ben een denker',
                        id: 4,
                    }
                ]
            },
        ],
    }

}

function MatrixMeasurement() {

    const { columnDefinitions, statements } = testdefinition.measurementContent;

    // State to store selected values for each row
    const [selectedValues, setSelectedValues] = useState({});

    // Function to handle radio input changes
    const handleRadioChange = (statementId, rowId, columnValue) => {
        setSelectedValues((prevSelectedValues) => {

            console.log(statementId, rowId, columnValue);
            
            const updatedValues = { ...prevSelectedValues };

            // Initialize an array for the statementId if it doesn't exist
            if (!updatedValues[statementId]) {
                updatedValues[statementId] = [];
            }

            // Check if the rowId is already selected for this statementId
            const existingIndex = updatedValues[statementId].findIndex(
                (item) => item.rowId === rowId
            );

            // If the rowId is already selected, update its columnValue
            if (existingIndex !== -1) {
                updatedValues[statementId][existingIndex].columnValue = columnValue;
            } else {
                // Otherwise, add a new object with rowId and columnValue
                updatedValues[statementId].push({ rowId, columnValue });
            }

            console.log(updatedValues);
            return updatedValues;
        });

    };

    return (
        <Container className='mt-5 bg-oranje'>

            <div className="matrix-grid">
                {/* Header Row */}
                <div className="header-row">
                    <div className="empty-cell"></div>
                    {columnDefinitions[0].columns.map((column, i) => (
                        <div key={i} className="column-header">
                            {column.title}
                        </div>
                    ))}
                </div>

                {/* Statements */}
                {statements.map((statement) => (
                    <div key={statement.id} className="statement">
                        {/* Title */}
                        <div className="title-cell">{statement.title}</div>

                        {/* Rows */}
                        {statement.rows.map((row) => (
                            <div key={row.id} className="row">
                                <div className="row-title">{row.title}</div>
                                {columnDefinitions[0].columns.map((column, i) => {

                                    const isChecked =
                                        selectedValues[statement.id] && selectedValues[statement.id][row.id]?.columnValue === column.value;

                                    return (

                                        <div key={`${row.id}_${column.value}`} className="radio-cell">
                                            <input
                                                type="radio"
                                                id={`radio_${row.id}_${column.value}`}
                                                name={`radio_${row.id}`}
                                                value={column.value}
                                                checked={isChecked}
                                                onChange={() => handleRadioChange(statement.id, row.id, column.value)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

        </Container>
    )
}

export default MatrixMeasurement