import { useAttributeCategoryTranslation } from "@/general/i18n/translation-helpers";
import { AttributeCategory } from "@/general/model/attribute-category";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { t } from "i18next";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './DevelopmentOverview.scss';
import ProfilePersonality from "./components/ProfilePersonality";
import ProfilePotential from "./components/ProfilePotential";
import ProfileSkills from "./components/ProfileSkills";
import ProfileEvaluation from "./components/profile-evaluation/ProfileEvaluation";

function DevelopmentOverview() {

    useDocumentTitle(t('documentTitles:development'));
    const { translateAttributeCategory } = useAttributeCategoryTranslation();
    const [activeTab, setActiveTab] = useState<AttributeCategory>(AttributeCategory.Personality);

    function RenderTabContent() {
        switch (activeTab) {
            case AttributeCategory.Personality:
                return <ProfilePersonality />;
            case AttributeCategory.Skills:
                return <ProfileSkills />;
            case AttributeCategory.Potential:
                return <ProfilePotential />;
            default:
                return <ProfilePersonality />;
        }
    }

    return (
        <div className="development-overview">
            <Container fluid>
                <Container>
                    <Row className='py-5'>
                        <Col sm={12} md={12} lg={6} className="header">
                            <h1 className="title">{t('development:title')}</h1>
                            <p>{t('development:description')}</p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className='tab-selection'>
                <Container>
                    <Row>
                        <Col sm={12} className="p-0">
                            <div className="tabs">
                                <div
                                    onClick={() => setActiveTab(AttributeCategory.Personality)}
                                    className={`tab ${activeTab === AttributeCategory.Personality ? 'tab--active' : ''}`}>
                                    {translateAttributeCategory(AttributeCategory.Personality)}
                                </div>
                                <div
                                    onClick={() => setActiveTab(AttributeCategory.Skills)}
                                    className={`tab ${activeTab === AttributeCategory.Skills ? 'tab--active' : ''}`}>
                                    {translateAttributeCategory(AttributeCategory.Skills)}
                                </div>
                                <div
                                    onClick={() => setActiveTab(AttributeCategory.Potential)}
                                    className={`tab ${activeTab === AttributeCategory.Potential ? 'tab--active' : ''}`}>
                                    {translateAttributeCategory(AttributeCategory.Potential)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {RenderTabContent()}

            <Container fluid className='bg-blauw py-5'>
                <Container>
                    <ProfileEvaluation attributeCategory={activeTab} />
                </Container>
            </Container>
        </div>
    )
}

export default DevelopmentOverview;
