import Button from '@/components/button/Button';
import LanguageSelector from '@/components/language-selector/LanguageSelector';
import ProfilePicture from '@/components/profile-picture/ProfilePicture';
import { ConfigContext } from '@/context/ConfigContext';
import { PlatformSettingsContext } from '@/context/PlatformSettingsContext';
import useCurrentAccount from '@/hooks/useCurrentAccount';
import useScrollbarPadding from '@/hooks/useScrollbarPadding';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Variants, motion } from 'framer-motion';
import { t } from 'i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './Header.scss';

function Header() {

    const menuRef = useRef(null);
    const configContext = useContext(ConfigContext);
    const platformSettingsContext = useContext(PlatformSettingsContext);
    const location = useLocation();
    const { i18n } = useTranslation();
    const { instance } = useMsal();
    const { currentAccount } = useCurrentAccount();
    const [menuOpen, setMenuOpen] = useState(false);

    useScrollbarPadding();

    useEffect(() => {
        setMenuOpen(false);
    }, [location]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, [menuRef]);

    function login() {
        instance.loginRedirect({ scopes: [...configContext?.configBody?.api?.endpoints?.scopes] })
            .catch((error) => console.log(error));
    }

    function logout() {
        instance.logoutRedirect();
    }

    function handleToggleClick(event) {
        event.stopPropagation();
        setMenuOpen(prevMenuOpen => !prevMenuOpen);
    }

    const dropdownVariants: Variants = {
        open: {
            scale: 1,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.5,
                delayChildren: 0.2,
                staggerChildren: 0.05
            },
            boxShadow: "var(--shadow-xl)",
        },
        closed: {
            scale: 0,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3
            },
            boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
        }
    };

    const itemVariants: Variants = {
        open: {
            opacity: 1,
            y: 0,
            transition: { type: "spring", stiffness: 300, damping: 24 }
        },
        closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
    };

    return (
        <Container fluid className='platform-header'>
            <Container className='platform-header-inner'>

                <div className='brand-logos'>
                    <Link to='/' className='logo'>
                        <img src={`/assets/img/werk-in-het-vooruitzicht-fullcolor.svg`} alt='' />
                    </Link>

                    {platformSettingsContext.logo &&
                        <>
                            <span className='divider' />
                            <div className='logo logo-partner'>
                                <img src={platformSettingsContext.logo} alt='' />
                            </div>
                        </>
                    }
                </div>

                <div className='actions'>

                    <AuthenticatedTemplate>
                        <motion.nav
                            className='navigation'
                            initial={false}
                            animate={menuOpen ? "open" : "closed"}>

                            <motion.div
                                className='navigation-toggle'
                                whileTap={{ scale: 0.95 }}
                                tabIndex={0}
                                onClick={handleToggleClick}
                                onKeyDown={(event) => { if (event.key === 'Enter') handleToggleClick(event) }}>

                                {currentAccount &&
                                    <span className='username'>
                                        {currentAccount.name.split(' ')[0] ?? currentAccount.name}
                                    </span>
                                }
                                <ProfilePicture width='32px' height='32px' editable={false} iconSize='fa-xs' />
                            </motion.div>

                            <motion.div variants={dropdownVariants} className={`navigation-dropdown ${menuOpen ? 'navigation-dropdown--open' : 'navigation-dropdown--closed'}`} ref={menuRef}>

                                <motion.div variants={itemVariants}>
                                    <Link to={'/profile'} className='dropdown-item'>
                                        <ProfilePicture width='24px' height='24px' editable={false} iconSize='fa-sm' />
                                        <span role='button'>{t('header:profile')}</span>
                                    </Link>
                                </motion.div>

                                <motion.div variants={itemVariants}>
                                    <Link to={'/settings'} className='dropdown-item'>
                                        <i className='fa-solid fa-cog' />
                                        <span role='button'>{t('header:settings')}</span>
                                    </Link>
                                </motion.div>

                                <motion.div variants={itemVariants}>
                                    <Link to={'/vouchers'} className='dropdown-item dropdown-item--highlight'>
                                        <i className='fa-solid fa-ticket' />
                                        <span role='button'>Verzilver voucher</span>
                                    </Link>
                                </motion.div>

                                <motion.div variants={itemVariants} className='divider'></motion.div>

                                <motion.div variants={itemVariants} className='dropdown-item dropdown-item--no-hover'>
                                    <img className='language-flag' src={`/assets/img/languages/${i18n.language}.svg`} alt={i18n.language} />
                                    <LanguageSelector />
                                </motion.div>

                                <motion.div variants={itemVariants} className='divider'></motion.div>

                                <motion.div variants={itemVariants} className='dropdown-item' tabIndex={0} onClick={() => logout()} onKeyDown={(event) => { if (event.key === 'Enter') logout() }}>
                                    <i className='fa-solid fa-sign-out' />
                                    <span role='button'>{t('header:logout')}</span>
                                </motion.div>
                            </motion.div>
                        </motion.nav>
                    </AuthenticatedTemplate>

                    <UnauthenticatedTemplate>
                        <LanguageSelector />
                        <Button text={t('header:login')} icon='sign-in' id='login' tabIndex={0} onClick={() => login()} />
                    </UnauthenticatedTemplate>
                </div>
            </Container>
        </Container>
    )
}

export default Header