import { Attribute } from "@/general/model/attribute";
import { DataOrigin } from "@/general/model/data-origin";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import './SubCategoryAttributes.scss';

export interface AttributeSubCategory {
    title: string;
    img: string;
    attributes: Attribute[];
}

type SubCategoryAttributesProps = {
    isLoading: boolean;
    attributeSubCategories: AttributeSubCategory[];
    attributeSelected?: Attribute;
    originSelected?: DataOrigin;
    onAttributeClick: (attribute: Attribute) => void;
}

function SubCategoryAttributes({ isLoading, attributeSubCategories, attributeSelected, originSelected, onAttributeClick }: SubCategoryAttributesProps) {
    return (
        <Row className="sub-category-attributes g-4">
            {attributeSubCategories?.map((subCategory, index) => (
                <Col sm={12} md={6} lg={4}>
                    <div className="attribute-card">
                        <img className="img" alt="" src={subCategory.img} />
                        <div className="attributes">
                            <h3 className="title">{subCategory.title}</h3>
                            <p className="content">
                                {subCategory.attributes?.map((attribute, index) => (
                                    <>
                                        <span className={`name ${(attributeSelected?.name == attribute.name || attribute.origins.some(x => x.displayValue == originSelected?.displayValue)) ? 'name--selected' : ''}`} key={attribute.id} onClick={() => onAttributeClick(attribute)}>
                                            {attribute.name}
                                        </span>
                                        <span>
                                            {index < subCategory.attributes.length - 1 && ', '}
                                        </span>
                                    </>
                                ))}
                            </p>
                        </div>
                    </div>
                </Col>
            ))}

            {isLoading &&
                <>
                    {[...Array(3)].map((_, index) => (
                        <Col sm={12} md={6} lg={4} key={index}>
                            <Skeleton height={315} borderRadius={25} style={{ opacity: 0.3 }} />
                        </Col>
                    ))}
                </>
            }
        </Row>
    )
}

export default SubCategoryAttributes