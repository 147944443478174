import PercentageRadialChart from '@/components/percentage-radial-chart/PercentageRadialChart';
import { RecommendedProfession } from '@/professions/model/recommended-profession';
import { t } from 'i18next';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './RecommendedProfessionTableRow.scss';
import LinkButton from '@/components/button/LinkButton';

type RecommendedProfessionTableRowProps = {
    recommendedProfession: RecommendedProfession
    ranking: number
}

function RecommendedProfessionTableRow({ recommendedProfession, ranking }: RecommendedProfessionTableRowProps) {

    const [showDetail, setShowDetail] = useState<boolean>(false);

    return (

        <tr className={`${showDetail ? 'opened' : ''}`} tabIndex={0} onClick={() => setShowDetail(!showDetail)}>

            <td className={`td-ranking ${showDetail ? '' : 'align-middle'}`}>
                <span className="text-oranje fs-3 ps-2 fw-bold">{ranking}</span>
            </td>

            <td className={`td-profession ${showDetail ? '' : 'align-middle'}`}>
                <div className='d-flex flex-column'>
                    <h5 className="mb-0">{recommendedProfession.name}</h5>
                    {showDetail &&
                        <>
                            <p className='td-description my-3'>{recommendedProfession.description.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                            <LinkButton text={t('professions:overview:professionCardBtn')} displayType='secondary' icon={'arrow-right'} to={'/professions/' + recommendedProfession.id} />
                        </>
                    }
                </div>
            </td>

            <td className="td-recommended text-center align-middle">
                <PercentageRadialChart
                    percentage={recommendedProfession.percentRecommended}
                    title={`Overall recommended percentage for profession ${recommendedProfession.name}`}
                    dimension={60}
                    strokeWidth={20}
                    fontSize={40} />
            </td>

            {recommendedProfession.attributeCategoryRecommendations.map((attributeCategory, index) => (

                <td key={index} className={`text-center text-muted td-attribute-category ${showDetail ? 'align-middle' : 'align-middle'}`}>
                    <div className="d-flex flex-column">
                        <span className={`${showDetail ? 'fw-bold pb-3' : ''}`}>
                            {attributeCategory.percentRecommended}%
                        </span>
                    </div>
                </td>
            ))}
        </tr>
    )
}

export default RecommendedProfessionTableRow