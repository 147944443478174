import FavoriteProfessionToggle from "@/components/favorite-toggle/FavoriteProfessionToggle";
import { Profession } from "@/professions/model/profession";
import { useIsAuthenticated } from "@azure/msal-react";
import { Link } from "react-router-dom";
import './ProfessionCard.scss';
import getMockImage from "@/general/utilities/MockImageRetriever";
import { useMemo } from "react";

type ProfessionCardProps = {
    profession: Profession;
}

function ProfessionCard({ profession }: ProfessionCardProps) {

    const isAuthenticated = useIsAuthenticated();
    const mockImage = useMemo(() => getMockImage('profession', profession.name), [profession.id]);

    return (
        <>
            {profession &&
                <Link to={`/professions/${profession.id}`} className='profession-card'>

                    <div className="img">
                        <img src={mockImage} alt='' />
                    </div>

                    {isAuthenticated &&
                        <div className="favorite">
                            <FavoriteProfessionToggle profession={profession} />
                        </div>
                    }

                    <div className='content'>
                        <h2 className='title'>{profession.name}</h2>
                        <div className='description' dangerouslySetInnerHTML={{ __html: profession.description }}></div>
                    </div>
                </Link>
            }
        </>
    )
}

export default ProfessionCard