import useFavoriteMeasurements from '@/hooks/useFavoriteMeasurements';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { t } from 'i18next';
import { Container } from 'react-bootstrap';
import MeasurementSwiper from './MeasurementSwiper';

function FavoriteMeasurements() {

    const { isLoading, favoriteMeasurements } = useFavoriteMeasurements();

    return (
        <AuthenticatedTemplate>
            {
                favoriteMeasurements?.length > 0 &&
                <Container fluid className="bg-lichtblauw py-5 position-relative">
                    <div className='colored-background'></div>
                    <Container className="position-relative">
                        <div>
                            <h2 className="d-flex align-items-center pb-3">
                                <span>{t('measurements:overview:favoritesTitle')}</span><i className='fas fa-star fa-xs ms-2' />
                            </h2>
                            <MeasurementSwiper measurements={favoriteMeasurements} />
                        </div>
                    </Container>
                </Container>
            }
        </AuthenticatedTemplate>
    )
}

export default FavoriteMeasurements