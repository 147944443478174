
import PageNotFound from '@/components/not-found-component/PageNotFound';
import { ProtectedRoute } from '@/layout/protected-route';
import { Route, Routes } from 'react-router-dom';
import FeedbackOverview from './FeedbackOverview';
import ReflectionOverview from './ReflectionOverview';

function EvaluationModule() {
    return (
        <Routes>
            <Route path="/feedback" element={<FeedbackOverview />} />
            <Route path="/reflection" element={
                <ProtectedRoute>
                    <ReflectionOverview />
                </ProtectedRoute>
            } />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default EvaluationModule;