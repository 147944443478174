import { t } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import './ErrorComponent.scss';

type ErrorComponentProps = {
    message?: string;
}

export function ErrorComponent({ message }: ErrorComponentProps) {

    return (
        <div className="page-error">
            <div className='page-error__bg-img'>
                <img src='/assets/img/WIHV_3D_Visual_Error.jpg' alt='' />
            </div>

            <Container fluid>
                <Container>
                    <Row className="page-error__content pt-5 pb-4">
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <h1 className='h1 font-heavy mb-4'>{t('errorPage:title')}</h1>
                            <p className='mb-4'>{t('errorPage:description')}</p>
                            {
                                message &&
                                <p className='pb-4 font-bold'>{message}</p>
                            }
                            <a className="btn btn--zwartblauw" onClick={() => window.location.reload()}>
                                {t('errorPage:retryBtn')}
                                <i className='fas fa-chevron-right ms-2'></i>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}
