import { ErrorComponent } from "@/components/error-component/ErrorComponent";
import UnsavedChangesGuard from "@/components/unsaved-changes-guard/UnsavedChangesGuard";
import { ConfigContext } from "@/context/ConfigContext";
import { Evaluation } from "@/development/models/evaluation";
import { EvaluationStatus } from "@/development/models/evaluation-status";
import { EvaluationType } from "@/development/models/evaluation-type";
import AttributeEvaluation from "@/evaluation/components/attribute-evaluation/AttributeEvaluation";
import { AttributeFeedback } from "@/general/model/attribute-feedback";
import useAuthenticatedRequest from "@/hooks/useAuthenticatedRequest";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import useProfileAttributes from "@/hooks/useProfileAttributes";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import './ReflectionOverview.scss';

function ReflectionOverview() {

    useDocumentTitle(t('documentTitles:reflection'));

    const configContext = useContext(ConfigContext);

    const [started, setStarted] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [evaluation, setEvaluation] = useState<Evaluation>(null);

    const navigate = useNavigate();
    const authenticatedRequest = useAuthenticatedRequest();

    const { profileAttributes, isLoading, error } = useProfileAttributes();

    useEffect(() => {
        if (profileAttributes && !evaluation) {
            const evaluation = {
                type: EvaluationType.Reflection,
                status: EvaluationStatus.Completed,
                attributeFeedbacks: profileAttributes.map((attribute) => {
                    return {
                        attributeId: attribute.id,
                        attributeName: attribute.name,
                        attributeCategory: attribute.category,
                        feedback: 0
                    }
                })
            } as Evaluation;
            setEvaluation(evaluation);
        }
    }, [profileAttributes]);

    function handleSaveReflection(feedbacks: AttributeFeedback[]) {

        const updatedEvaluation = {
            ...evaluation,
            attributeFeedbacks: feedbacks
        } as Evaluation;

        setIsSubmitting(true);

        authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.evaluations.list, 'POST', updatedEvaluation)
            .then(() => {
                toast.success(t('evaluation:toasts:reflectionSaveSuccess'));
                setSaved(true);
                setTimeout(() => {
                    navigate('/development');
                }, 400);
            }).catch((error) => {
                toast.error(t('evaluation:toasts:reflectionSaveFailed'));
                console.error(error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    return (
        <div className="reflection-overview">

            <div className='background-img'>
                <img src='/assets/img/WIHV_3D_Visual_Rood.jpg' style={{ objectPosition: 'center center' }} alt='' />
            </div>

            <Container fluid className='content py-5'>
                <Container>

                    {isLoading &&
                        <Row className='position-relative pt-5'>
                            <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                <div className="font-heavy h1 pb-3">{t('evaluation:reflection:loadingTitle')}</div>
                                <p className="pb-4">
                                    {t('evaluation:reflection:loadingDescription')}
                                </p>
                            </Col>
                        </Row>
                    }

                    {!started && evaluation &&
                        <Row className='position-relative pt-5'>
                            <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                <div className="font-heavy h1 pb-3">{t('evaluation:reflection:startTitle', { name: evaluation.createdBy })}</div>
                                <p className="h4 text-geel font-bold pb-3">{t('evaluation:reflection:startSubtitle')}</p>
                                <p className="pb-4">
                                    {t('evaluation:reflection:startDescription')}
                                </p>
                                <button className="btn btn--zwartblauw" onClick={() => setStarted(true)}>
                                    <span>{t('evaluation:reflection:startBtn')}</span>
                                    <i className="fas fa-chevron-right ms-3" />
                                </button>
                            </Col>
                        </Row>
                    }

                    {error &&
                        <ErrorComponent message={error} />
                    }

                    {started && evaluation &&
                        <>
                            <Col sm={12} md={{ span: 6, offset: 3 }} className='intro'>
                                <h1 className="title">{t('evaluation:reflection:title')}</h1>
                                <h2 className="subtitle">{t('evaluation:reflection:subtitle')}</h2>
                                <p className="description">{t('evaluation:reflection:description')}</p>
                            </Col>
                            <AttributeEvaluation evaluation={evaluation} isSubmitting={isSubmitting} onSave={(feedbacks) => handleSaveReflection(feedbacks)} />
                        </>
                    }

                </Container>
            </Container>

            <UnsavedChangesGuard shouldBlock={(started && !saved)} />
        </div>

    )
}

export default ReflectionOverview