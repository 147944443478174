import FavoriteLearningResourceToggle from "@/components/favorite-toggle/FavoriteLearningResourceToggle";
import SwiperComponent from "@/components/swiper-component/SwiperComponent";
import useCompletedLearningResources from "@/hooks/useCompletedLearningResources";
import { useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { SwiperSlide } from 'swiper/react';
import { LearningResource } from "../models/learning-resource";
import { LearningResourceType } from "../models/learning-resource-type";
import './LearningResourceSwiper.scss';

type LearningResourceSwiperProps = {
    resources: LearningResource[];
    showIndex?: boolean;
}

function LearningResourceSwiper({ resources, showIndex = false }: LearningResourceSwiperProps) {

    const isAuthenticated = useIsAuthenticated();
    const { completedLearningResources } = useCompletedLearningResources();

    const resourceTypeMapping = {
        [LearningResourceType.LearningCourse]: {
            type: 'course',
            icon: 'fas fa-graduation-cap',
            translation: t('learning:overview:learningCourse'),
            buttonTranslation: t('learning:overview:learningCourseCardBtn')
        },
        [LearningResourceType.LearningPath]: {
            type: 'path',
            icon: 'fas fa-sitemap',
            translation: t('learning:overview:learningPath'),
            buttonTranslation: t('learning:overview:learningPathCardBtn')
        }
    };

    return (
        <SwiperComponent loop={false}>
            {resources?.map((resource) => (
                <SwiperSlide key={resource.id} className={`learning-resource-slide learning-resource-slide--${resourceTypeMapping[resource.resourceType].type}`}>
                    <Link to={`/learning/${resourceTypeMapping[resource.resourceType].type}/${resource.id}`}>

                        {isAuthenticated &&
                            <FavoriteLearningResourceToggle resource={resource} />
                        }

                        <img
                            src={resource.imageUrl || '/assets/img/WIHV_3D_Visual_Leerpad.jpg'}
                            className="bg-img"
                            alt=""
                        />

                        <div className="banners">
                            {resource.resourceType == LearningResourceType.LearningPath &&
                                <div className='type'>
                                    <i className="fas fa-sitemap"></i>
                                </div>
                            }

                            {completedLearningResources?.some(x => x.id == resource.id) &&
                                <div className="completed">
                                    <i className="fas fa-check"></i>
                                </div>
                            }
                        </div>

                        <div className="content">
                            <h2 className="title">{resource.name}</h2>
                            <p className="description">{resource.description.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                        </div>
                    </Link>
                </SwiperSlide>
            ))}
        </SwiperComponent>
    )
}

export default LearningResourceSwiper