import { Attribute } from "@/general/model/attribute";
import { DataOrigin, OriginType } from "@/general/model/data-origin";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import './ProfileAttributeOrigins.scss';

type ProfileAttributeOriginsProps = {
    title: string;
    isLoading: boolean;
    attributes: Attribute[];
    attributeSelected?: Attribute;
    originSelected?: DataOrigin;
    onOriginClick: (origin: DataOrigin) => void;
}

function ProfileAttributeOrigins({ title, isLoading, attributes, attributeSelected, originSelected, onOriginClick }: ProfileAttributeOriginsProps) {

    const origins = attributes?.flatMap((attribute) => attribute.origins);

    function getLinkByOriginType(origin: DataOrigin): string {
        switch (origin.type) {
            case OriginType.Measurement:
                return `/measurements/${origin.value}/results`;
            case OriginType.InputFields:
                return '/profile';
            default:
                return '/';
        }
    }

    const uniqueOrigins: DataOrigin[] = origins?.reduce((acc: DataOrigin[], origin) => {
        if (!acc.some((o) => o.displayValue === origin.displayValue)) {
            acc.push(origin);
        }
        return acc;
    }, []);

    return (
        <div className='attribute-origins'>
            <h3 className='attribute-origins__title'>{title}</h3>
            <p className='attribute-origins__description'>Klik op een bron om te bekijken welk attribuut hieraan gekoppeld is. Andersom kan ook, klik op een attribuut om te zien welke bronnen hieraan gekoppeld zijn. Via de knop kan je navigeren naar de bron.</p>
            <div className='attribute-origins__list'>

                {isLoading &&
                    <>
                        <Skeleton height={48} width={220} borderRadius={25} count={5} inline={true} className="me-3" style={{opacity: 0.8}} />
                        <Skeleton height={48} width={180} borderRadius={25} count={4} inline={true} className="me-3" style={{opacity: 0.8}} />
                    </>
                }

                {uniqueOrigins?.map((origin, i) => (
                    <div className={`attribute-origins__origin ${(originSelected?.displayValue == origin.displayValue || attributeSelected?.origins.some(x => x.displayValue == origin.displayValue)) ? 'selected' : ''}`} key={i} onClick={() => onOriginClick(origin)}>
                        <div className="icon">
                            {origin.type === OriginType.Measurement && <i className='fas fa-clipboard-question fa-lg me-3'></i>}
                            {origin.type === OriginType.InputFields && <i className='fas fa-user-edit fa-lg me-3'></i>}
                            {origin.type === OriginType.Favorites && <i className='fas fa-heart fa-lg me-3'></i>}
                            {origin.type === OriginType.Uploads && <i className='fas fa-upload fa-lg me-3'></i>}
                            {origin.type === OriginType.Feedback && <i className='fas fa-comments fa-lg me-3'></i>}
                        </div>
                        <span className={`name`}>{origin.displayValue}</span>
                        <Link to={getLinkByOriginType(origin)} className="link">
                            <i className='fas fa-chevron-right'></i>
                        </Link>
                    </div>
                ))}

                {uniqueOrigins?.length === 0 &&
                    <div className=''>
                        <span>Geen bronnen gevonden</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProfileAttributeOrigins