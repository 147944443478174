import formatDuration from '@/general/utilities/DurationStringFormatter';
import { LearningCourse } from '@/learning/models/learning-course';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import AnchorButton from '../button/AnchorButton';
import './LearningPathCourse.scss';

type LearningPathCourseProps = {
    course: LearningCourse;
    visible: boolean;
}

function LearningPathCourse({ course, visible }: LearningPathCourseProps) {
    return (
        <li key={course.id} className="learning-path-course">
            <div className='course'>
                <div className='image'>
                    <img src={course.imageUrl} alt={course.name} />
                </div>
                <div className='information'>
                    <Link to={`/learning/course/${course.id}`} tabIndex={visible ? 0 : -1} className="title">{course.name}</Link>
                    <div className='metadata'>
                        <span className="duration">
                            <i className="fas fa-clock fa-sm" />
                            {formatDuration(course.durationInMinutes)}
                        </span>
                        <span className="experience-level">
                            <i className="fas fa-chart-line fa-sm" />
                            {course.experienceLevel}
                        </span>
                        <span className='language'>
                            <i className="fas fa-globe fa-sm" />
                            {course.languageName}
                        </span>
                    </div>
                </div>
                <div className="actions">
                    <AnchorButton href={course.resourceUrl} tabIndex={visible ? 0 : -1} displayType='secondary' text={t('learning:courseDetail:startBtn')} icon='play' />
                </div>
            </div>
        </li>
    )
}

export default LearningPathCourse