import useFavoriteProfessions from '@/hooks/useFavoriteProfessions';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { t } from 'i18next';
import { Container } from 'react-bootstrap';
import ProfessionSwiper from './ProfessionSwiper';

function FavoriteProfessions() {

    const { isLoading, favoriteProfessions } = useFavoriteProfessions();

    return (
        <AuthenticatedTemplate>
            {
                favoriteProfessions?.length > 0 &&
                <Container fluid className="bg-lichtblauw py-5 position-relative">
                    <div className='colored-background'></div>
                    <Container className="position-relative">
                        <div>
                            <h2 className="d-flex align-items-center pb-3">
                                <span>{t('professions:overview:favoritesTitle')}</span><i className='fas fa-star fa-xs ms-2' />
                            </h2>
                            <ProfessionSwiper professions={favoriteProfessions} />
                        </div>
                    </Container>
                </Container>
            }
        </AuthenticatedTemplate>
    )
}

export default FavoriteProfessions