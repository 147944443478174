import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PageNotFound.scss';

function PageNotFound() {

    const isAuthenticated = useIsAuthenticated();

    return (
        <div className="page-not-found">
            <div className='page-not-found__bg-img'>
                <img src='/assets/img/WIHV_3D_Visual_Error.jpg' alt='' />
            </div>

            <Container fluid>
                <Container>
                    <Row className="page-not-found__content pt-5 pb-4">
                        <Col sm={12} md={5} lg={5} xl={5}>
                            <h1 className='h1 font-heavy mb-4'>{t('notFoundPage:title')}</h1>
                            <p className='mb-4'>{t('notFoundPage:description')}</p>
                            <Link to={isAuthenticated ? '/profile' : '/'} className='btn btn--zwartblauw'>
                                {isAuthenticated ? t('notFoundPage:profileBtn') : t('notFoundPage:homeBtn')}
                                <i className='fas fa-chevron-right ms-2'></i>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default PageNotFound