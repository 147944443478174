import { ConfigContext } from '@/context/ConfigContext';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import useSWR from 'swr';
import { FileUpload } from '../model/file-upload';
import './ProfileCard.scss';
import ProfileUploadsFile from './ProfileUploadsFile';
import ProfileUploadsModal from './ProfileUploadsModal';
import { motion } from 'framer-motion';

function ProfileUploads() {

    const configContext = useContext(ConfigContext);
    const [showUploadsModal, setShowUploadsModal] = useState<boolean>(false);
    
    const { data: fileUploads, isLoading, error, mutate } = useSWR<FileUpload[]>(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.uploads.list, { shouldRetryOnError: false });

    return (
        <>
            <Card className="profile-card profile-uploads mb-4">
                <Card.Header>
                    <Card.Title>{t('profile:uploads:title')}</Card.Title>
                    <motion.button whileTap={{scale: 0.85}} onClick={() => setShowUploadsModal(true)}><i className="fa-solid fa-pen" /></motion.button>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        {!isLoading && fileUploads?.map((file, i) => (
                            <ProfileUploadsFile key={i} file={file} />
                        ))}

                        {!isLoading && fileUploads?.length === 0 &&
                            <div className='card-item'>
                                <p>{t('profile:uploads:noUploads')}</p>
                            </div>
                        }

                        {isLoading &&
                            <Skeleton height={48} count={3} className='mb-3' />
                        }
                    </Card.Text >
                </Card.Body>
            </Card>

            <ProfileUploadsModal showModal={showUploadsModal} onClose={() => setShowUploadsModal(false)} />
        </>
    );
}

export default ProfileUploads