import { Row, Col } from 'react-bootstrap';
import './swiper-locked-component.scss';

type SwiperLockedComponentProps = {
    children: React.ReactNode;
}

function SwiperLockedComponent({ children }: SwiperLockedComponentProps) {
    return (
        <Row className="mb-lg-5 position-relative">
            <Col sm={6} md={4} lg={3} xl={3} xxl={3}>
                <div className="locked-card w-100 default-br d-block">
                    <i className="fas fa-lock fa-xl" />
                </div>
            </Col>
            <Col sm={6} md={4} lg={3} xl={3} xxl={3}>
                <div className="locked-card w-100 default-br d-none d-sm-block">
                    <i className="fas fa-lock fa-xl" />
                </div>
            </Col>
            <Col sm={0} md={4} lg={3} xl={3} xxl={3}>
                <div className="locked-card w-100 default-br d-none d-md-block">
                    <i className="fas fa-lock fa-xl" />
                </div>
            </Col>
            <Col sm={0} md={0} lg={3} xl={3} xxl={3}>
                <div className="locked-card w-100 default-br d-none d-lg-block">
                    <i className="fas fa-lock fa-xl" />
                </div>
            </Col>
            <div className="locked-cta-card bg-wit default-br p-4">
                {children}
            </div>
        </Row>
    )
}

export default SwiperLockedComponent