import { Link, LinkProps } from 'react-router-dom';

type LinkButtonProps = {
    text: string
    icon?: string
    iconSet?: 'fas' | 'far' | 'fab'
    loading?: boolean
    disabled?: boolean
    displayType?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'danger'
} & LinkProps;

function LinkButton({ text, icon, iconSet = 'fas', loading, disabled, displayType = 'primary', ...linkProps }: LinkButtonProps) {
    return (
        <Link className={`button button--${displayType} ${disabled ? 'button--disabled' : ''} ${loading ? 'button--loading' : ''}`} {...linkProps}>
            {text}
            {icon && !loading && <i className={`icon ${iconSet} fa-${icon}`} />}
            {loading && <i className='loading-icon fas fa-spinner fa-spin' />}
        </Link>
    )
}

export default LinkButton;