import { ErrorComponent } from '@/components/error-component/ErrorComponent';
import FavoriteLearningResourceToggle from '@/components/favorite-toggle/FavoriteLearningResourceToggle';
import LearningPathContent from '@/components/learning-path/LearningPathContent';
import { LoadingComponent } from '@/components/loading-component/LoadingComponent';
import { ConfigContext } from '@/context/ConfigContext';
import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { LearningPath } from '../models/learning-path';
import './LearningPathDetail.scss';
import LearningResourceMetadata from './LearningResourceMetadata';

function LearningPathDetail() {

    const isAuthenticated = useIsAuthenticated();
    const configContext = useContext(ConfigContext);
    const { pathId } = useParams();

    const baseUrl = isAuthenticated ? configContext.configBody.api.baseUrl : configContext.configBody.api.publicBaseUrl;
    const { data: learningPath, isLoading, error, mutate } = useSWR<LearningPath>(pathId ? baseUrl + configContext.configBody.api.endpoints.learningPaths.detail.replace('{id}', pathId) : null);

    return (
        <>
            {isLoading &&
                <LoadingComponent message={t('learning:pathDetail:loading')} />
            }

            {error &&
                <ErrorComponent message={t('learning:pathDetail:error')} />
            }

            {learningPath &&
                <div className='learningPath-detail'>

                    <Container fluid className="learningPath-detail__header bg-roze">
                        <Container className='py-2 py-md-4 position-relative'>

                            <div className='learningPath-detail__header-img px-0'>
                                <img src={learningPath.imageUrl || '/assets/img/WIHV_3D_Visual_Leerpad.jpg'} alt='' />
                            </div>

                            <Row className='position-relative'>
                                <Col sm={12} md={12} lg={5}>
                                    <div className='learningPath-detail__type'>
                                        {learningPath.retailPrice == 0 && <span className='learningPath-detail__price'>{t('learning:detail:free')}</span>}
                                        <span>{t('learning:overview:learningPath')}</span>
                                    </div>
                                    <h1 className='learningPath-detail__title'>
                                        <span>{learningPath.name}</span>
                                        {isAuthenticated &&
                                            <FavoriteLearningResourceToggle resource={learningPath} lightTheme={false} />
                                        }
                                    </h1>
                                    <p className='learningPath-detail__description'>{learningPath.shortDescription}</p>
                                    <div className='learningPath-detail__btn'>
                                        <a className='btn btn--oranje' href={learningPath.resourceUrl} target='_blank'>
                                            {t('learning:pathDetail:startBtn')}
                                            <i className='fas fa-arrow-up-right-from-square ms-3' />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <LearningResourceMetadata learningResource={learningPath} />

                    <Container fluid className="bg-lichtblauw py-5">
                        <Container>
                            <Row className='mb-5'>
                                <Col sm={{ span: 8, offset: 2 }}>
                                    <h2 className='learningPath-detail__content-title'>{t('learning:pathDetail:contentTitle')}</h2>
                                    <p>{learningPath.description}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={{ span: 8, offset: 2 }}>
                                    <LearningPathContent learningPath={learningPath} opened={true} />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
            }
        </>
    )
}

export default LearningPathDetail