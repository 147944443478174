import { MotionProps, motion } from 'framer-motion';
import { ButtonHTMLAttributes } from 'react';
import './Button.scss';

type ButtonProps = {
    text: string
    icon?: string
    iconSet?: 'fas' | 'far' | 'fab'
    loading?: boolean
    disabled?: boolean
    displayType?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'danger'
    className?: string
} & ButtonHTMLAttributes<HTMLButtonElement>;

function Button({ text, icon, iconSet = 'fas', loading, disabled, displayType = 'primary', className, ...buttonProps }: ButtonProps) {

    return (
        <motion.button whileTap={{ scale: 0.9 }} className={`button button--${displayType} ${disabled ? 'button--disabled' : ''} ${loading ? 'button--loading' : ''} ${className}`} {...(buttonProps as ButtonHTMLAttributes<HTMLButtonElement> & MotionProps)}>
            {text}
            {icon && !loading && <i className={`icon ${iconSet} fa-${icon}`} />}
            {loading && <i className='loading-icon fas fa-spinner fa-spin' />}
        </motion.button>
    )
}

export default Button