import SettingsProvider from '@/context/settings-provider';
import SettingsOverview from './SettingsOverview';
import { ProtectedRoute } from '@/layout/protected-route';

function SettingsModule() {

    return (
        <ProtectedRoute>
            <SettingsProvider>
                <SettingsOverview />
            </SettingsProvider>
        </ProtectedRoute>
    )
}

export default SettingsModule;