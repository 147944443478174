import FavoriteProfessionToggle from "@/components/favorite-toggle/FavoriteProfessionToggle";
import SwiperComponent from "@/components/swiper-component/SwiperComponent";
import getMockImage from "@/general/utilities/MockImageRetriever";
import { useIsAuthenticated } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { SwiperSlide } from 'swiper/react';
import { Profession } from "../model/profession";
import './ProfessionSwiper.scss';

type ProfessionSwiperProps = {
    professions: Profession[];
    showIndex?: boolean;
}

function ProfessionSwiper({ professions, showIndex = false }: ProfessionSwiperProps) {

    const isAuthenticated = useIsAuthenticated();

    return (

        <SwiperComponent loop={false}>
            {professions.map((profession) => (
                <SwiperSlide key={profession.id} className={`profession-slide`}>
                    <Link to={`/professions/${profession.id}`}>
                        {isAuthenticated &&
                            <FavoriteProfessionToggle profession={profession} />
                        }

                        {showIndex &&
                            <div className="index">{professions.indexOf(profession) + 1}</div>
                        }

                        <img src={getMockImage('profession', profession.name)} className="bg-img" alt="" />

                        <div className="content">
                            <h2 className="title">{profession.name}</h2>
                            <p className="description">{profession.description.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                        </div>
                    </Link>
                </SwiperSlide>
            ))}
        </SwiperComponent>
    )
}

export default ProfessionSwiper