import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollbarPadding() {
    const location = useLocation();
    
    useEffect(() => {
        const checkScrollbar = () => {
            if (document.body.scrollHeight > window.innerHeight) {
                document.body.classList.add('has-scrollbar');
            } else {
                document.body.classList.remove('has-scrollbar');
            }
        };

        // Check scrollbar when component mounts
        setTimeout(() => {
            checkScrollbar();
        }, 500);

        // Check scrollbar when window resizes
        window.addEventListener('resize', checkScrollbar);

        // Clean up event listener when component unmounts
        return () => {
            window.removeEventListener('resize', checkScrollbar);
        };

    }, [location]); // Empty dependency array means this effect runs once on mount and clean up on unmount
}

export default useScrollbarPadding;