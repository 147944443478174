import FavoriteMeasurementToggle from '@/components/favorite-toggle/FavoriteMeasurementToggle';
import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { MeasurementDefinition } from '../model/measurement-definitions/measurement-definition';
import MeasurementInfo from './MeasurementInfo';
import './MeasurementStart.scss';
import Button from '@/components/button/Button';

type MeasurementStartComponentProps = {
    isLoading: boolean;
    measurement: MeasurementDefinition;
    onStartMeasurement: () => void;
}

function MeasurementStart({ isLoading, measurement, onStartMeasurement }: MeasurementStartComponentProps) {

    const isAuthenticated = useIsAuthenticated();

    return (
        <div className='measurement-start'>

            <Container fluid className="position-relative bg-paars py-5">
                <Container>
                    <Row className='header pt-5 pb-0 pb-md-5'>

                        <div className='background-img px-0'>
                            <img src={measurement?.imageUrl} alt='' />
                        </div>

                        <Col sm={12} md={12} lg={7} className='info position-relative'>

                            {isLoading &&
                                <>
                                    <Skeleton width={'60%'} height={48} className='mb-3' style={{ opacity: 0.7 }} />
                                    <Skeleton height={16} count={3} style={{ opacity: 0.7 }} />
                                </>
                            }

                            {!isLoading && measurement &&
                                <>
                                    <h1 className='title'>
                                        {measurement?.title}
                                        {isAuthenticated &&
                                            <FavoriteMeasurementToggle measurement={measurement} />
                                        }
                                    </h1>
                                    <p className='intro mt-3'>{measurement?.introduction}</p>
                                </>
                            }
                        </Col>

                        <Col sm={12} md={12} lg={{ span: 3, offset: 2 }} className='metadata position-relative mt-4 mt-lg-0'>
                            {isLoading &&
                                <>
                                    <Skeleton height={36} count={3} className='my-2' style={{ opacity: 0.7 }} />
                                    <Skeleton height={48} borderRadius={100} width={'60%'} className='mt-3' style={{ opacity: 0.7 }} />
                                </>
                            }

                            {!isLoading && measurement &&
                                <>
                                    <h2 className='title'>{t('measurements:start:aboutTitle')}</h2>
                                    <div className='duration'>
                                        <i className='icon fas fa-clock me-2' />
                                        {t('measurements:start:duration')}{measurement?.durationInMinutes}{t('measurements:start:minutes')}
                                    </div>

                                    {isNaN(Date.parse(measurement?.completed)) &&
                                        <div className='warning'>
                                            <i className='icon fas fa-exclamation-triangle me-2' />
                                            {t('measurements:start:overwriteWarning')}
                                        </div>
                                    }

                                    {measurement.isPublic && !isAuthenticated &&
                                        <div className='warning'>
                                            <i className='icon fas fa-exclamation-triangle me-2' />
                                            {t('measurements:start:notAuthenticatedWarning')}
                                        </div>
                                    }

                                    <Button text={t('measurements:start:startBtn')} icon='arrow-right' onClick={onStartMeasurement} />
                                </>
                            }

                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="py-5">
                <Container>
                    <MeasurementInfo isLoading={isLoading} description={measurement?.description} attributes={measurement?.attributes} />
                </Container>
            </Container>
        </div>
    )
}

export default MeasurementStart