import { Attribute, PersonalityAttribute, PersonalitySubCategory } from '@/general/model/attribute';
import { AttributeCategory } from '@/general/model/attribute-category';
import { DataOrigin } from '@/general/model/data-origin';
import useProfileAttributes from '@/hooks/useProfileAttributes';
import { t } from 'i18next';
import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import ProfileAttributeOrigins from './ProfileAttributeOrigins';
import SubCategoryAttributes, { AttributeSubCategory } from './SubCategoryAttributes';

function ProfilePersonality() {

    const { profileAttributes, isLoading } = useProfileAttributes();
    const personalityAttributes = profileAttributes?.filter((attribute) => attribute.category === AttributeCategory.Personality) as PersonalityAttribute[];
    const [originSelected, setOriginSelected] = useState<DataOrigin | undefined>(undefined);
    const [attributeSelected, setAttributeSelected] = useState<Attribute | undefined>(undefined);

    function handleOriginClick(origin: DataOrigin) {
        setAttributeSelected(undefined);
        setOriginSelected(origin.displayValue !== originSelected?.displayValue ? origin : undefined)
    }

    function handleAttributeClick(attribute: Attribute) {
        setOriginSelected(undefined);
        setAttributeSelected(attribute.id !== attributeSelected?.id ? attribute : undefined)
    }

    const attributeSubCategories: AttributeSubCategory[] = [
        {
            title: 'Wie ik ben',
            img: '/assets/img/profile/identity.jpg',
            attributes: personalityAttributes?.filter((attribute) => attribute.subCategory === PersonalitySubCategory.Identity)
        },
        {
            title: 'Hoe ik ben',
            img: '/assets/img/profile/behavior.jpg',
            attributes: personalityAttributes?.filter((attribute) => attribute.subCategory === PersonalitySubCategory.Behavior)
        },
        {
            title: 'Waar ik voor sta',
            img: '/assets/img/profile/values.jpg',
            attributes: personalityAttributes?.filter((attribute) => attribute.subCategory === PersonalitySubCategory.Values)
        },
        {
            title: 'Wat mij interesseert',
            img: '/assets/img/profile/interests.jpg',
            attributes: personalityAttributes?.filter((attribute) => attribute.subCategory === PersonalitySubCategory.Interests)
        },
        {
            title: 'Wat ik wil',
            img: '/assets/img/profile/goals.jpg',
            attributes: personalityAttributes?.filter((attribute) => attribute.subCategory === PersonalitySubCategory.Goals)
        }
    ];

    return (
        <>
            <Container fluid className='bg-paars py-5'>
                <Container>
                    <SubCategoryAttributes
                        attributeSelected={attributeSelected}
                        originSelected={originSelected}
                        onAttributeClick={(a) => { handleAttributeClick(a) }}
                        attributeSubCategories={attributeSubCategories}
                        isLoading={isLoading} />
                </Container>
            </Container>

            <Container fluid className='bg-lichtblauw py-5'>
                <Container>
                    <Row>
                        <ProfileAttributeOrigins
                            title={t('development:personalityOriginsTitle')}
                            attributes={personalityAttributes}
                            originSelected={originSelected}
                            attributeSelected={attributeSelected}
                            onOriginClick={(o) => { handleOriginClick(o) }}
                            isLoading={isLoading} />
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default ProfilePersonality