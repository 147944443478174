import LinkButton from "@/components/button/LinkButton";
import SwiperLockedComponent from "@/components/swiper-locked-component/swiper-locked-component";
import useRecommendedMeasurements from "@/hooks/useRecommendedMeasurements";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { t } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import MeasurementSwiper from "./MeasurementSwiper";

function RecommendedMeasurements() {

    const { recommendedMeasurements, isLoading } = useRecommendedMeasurements();

    return (
        <>
            <AuthenticatedTemplate>
                {
                    recommendedMeasurements?.length > 0 &&
                    <Container fluid className="bg-lila py-5">
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <div className="pb-3 pt-3">
                                        <h2>{t('measurements:overview:recommendedTitle')}</h2>
                                    </div>

                                    <div>
                                        <MeasurementSwiper measurements={recommendedMeasurements} />
                                        {/* TODO: add skeleton loader */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                }
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Container fluid className="bg-lila py-5">
                    <Container>
                        <Row>
                            <Col>
                                <div className="pb-3 pt-3">
                                    <h2>{t('measurements:overview:recommendedTitle')}</h2>
                                </div>
                                <SwiperLockedComponent>
                                    <h2 className="pb-2">{t('measurements:overview:recommendedLockedTitle')}</h2>
                                    <p className="pb-3">{t('measurements:overview:recommendedLockedDescription')}</p>
                                    <LinkButton to={'/profile'} text={t('measurements:overview:recommendedLockedBtn')} icon='chevron-right' />
                                </SwiperLockedComponent>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </UnauthenticatedTemplate>
        </>
    )
}

export default RecommendedMeasurements