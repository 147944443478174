import Button from "@/components/button/Button";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './VouchersOverview.scss';
import RedeemVoucherModal from "./components/RedeemVoucherModal";

const features = [
    {
        title: 'Toegang tot het platform',
        free: true,
        premium: true
    },
    {
        title: 'Orienteren op beroepen',
        free: true,
        premium: true
    },
    {
        title: 'Volledig leeraanbod',
        free: false,
        premium: true
    },
    {
        title: 'Aanbevelingen',
        free: false,
        premium: true
    },
    {
        title: 'AI Video analyses',
        free: false,
        premium: true
    }
]

function VouchersOverview() {

    const [showRedeemVoucherModal, setShowRedeemVoucherModal] = useState(false);


    return (
        <>
            <Container fluid className='vouchers-overview'>
                <Container>
                    {/* Unauthenticated: Create account CTA */}
                    {/* How to get a voucher */}

                    <Row>
                        <Col sm={12} className="py-5">
                            <div className="hero">
                                <h1 className="title">Jouw succes start hier</h1>
                                <p className="subtitle">Complementeer profiel. Leer skills. Level up.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="pb-5">
                        <Col sm={12} className="p-0">

                            <section className="plans">

                                <div className="plan-comparison">

                                    <div className="plan plan--free">

                                        <div className="header">
                                            <div className="title">
                                                <h2>Free</h2>
                                            </div>
                                            <div className="description">Geniet van onze basisfuncties zonder kosten, perfect voor het verkennen van ons platform.</div>
                                        </div>

                                        <div className="price">
                                            <span>Gratis</span>
                                        </div>

                                        <ul className="feature-list">
                                            {features.map((feature, index) => (
                                                <li key={index} className={`feature ${feature.free ? '' : 'feature--disabled'}`}>
                                                    {feature.free ? <i className="icon fas fa-check"></i> : <i className="icon fas fa-times"></i>}
                                                    {feature.title}
                                                </li>
                                            ))}
                                        </ul>

                                        <Button text={"Vraag voucher aan"} icon="bag-shopping" displayType="secondary" />
                                    </div>

                                    <div className="plan plan--premium">

                                        <div className="header">
                                            <div className="title">
                                                <h2>Premium</h2>
                                                <div className="label">Meest populair</div>
                                            </div>
                                            <div className="description">Ontgrendel alle functies, maximaliseer je productiviteit en haal het beste uit ons platform.</div>
                                        </div>

                                        <div className="price">€ 199 <span className="billing-cycle">/ éénmalig</span></div>

                                        <ul className="feature-list">
                                            {features.map((feature, index) => (
                                                <li key={index} className={`feature ${feature.premium ? '' : 'feature--disabled'}`}>
                                                    {feature.premium ? <i className="icon fas fa-check"></i> : <i className="icon fas fa-times"></i>}
                                                    {feature.title}
                                                </li>
                                            ))}
                                        </ul>

                                        <Button text={"Verzilver voucher"} icon="key" onClick={() => { setShowRedeemVoucherModal(true) }} />
                                    </div>
                                </div>

                            </section>
                        </Col>
                    </Row>

                </Container>
            </Container>

            <RedeemVoucherModal showModal={showRedeemVoucherModal} onSuccess={() => { }} onClose={() => setShowRedeemVoucherModal(false)} />
        </>
    )
}

export default VouchersOverview