
import PageNotFound from '@/components/not-found-component/PageNotFound';
import ProfileProvider from '@/context/ProfileProvider';
import { ProtectedRoute } from '@/layout/protected-route';
import { Route, Routes } from 'react-router-dom';
import DevelopmentOverview from './DevelopmentOverview';

function DevelopmentModule() {
    return (
        <ProtectedRoute>
            <Routes>
                <Route path="/" index element={
                    <ProfileProvider>
                        <DevelopmentOverview />
                    </ProfileProvider>
                } />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </ProtectedRoute>
    )
}

export default DevelopmentModule;