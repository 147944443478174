import { Attribute, SkillsAttribute, SkillsSubCategory } from '@/general/model/attribute';
import { AttributeCategory } from '@/general/model/attribute-category';
import { DataOrigin } from '@/general/model/data-origin';
import useProfileAttributes from '@/hooks/useProfileAttributes';
import { t } from 'i18next';
import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import ProfileAttributeOrigins from './ProfileAttributeOrigins';
import SubCategoryAttributes, { AttributeSubCategory } from './SubCategoryAttributes';

function ProfileSkills() {

    const { profileAttributes, isLoading } = useProfileAttributes();
    const skillAttributes = profileAttributes?.filter((attribute) => attribute.category === AttributeCategory.Skills) as SkillsAttribute[];
    const [originSelected, setOriginSelected] = useState<DataOrigin | undefined>(undefined);
    const [attributeSelected, setAttributeSelected] = useState<Attribute | undefined>(undefined);

    function handleOriginClick(origin: DataOrigin) {
        setAttributeSelected(undefined);
        setOriginSelected(origin.displayValue !== originSelected?.displayValue ? origin : undefined)
    }

    function handleAttributeClick(attribute: Attribute) {
        setOriginSelected(undefined);
        setAttributeSelected(attribute.id !== attributeSelected?.id ? attribute : undefined)
    }

    const attributeSubCategories: AttributeSubCategory[] = [
        {
            title: 'Wat ik weet',
            img: '/assets/img/WIHV_3D_Visual_Leerpad.jpg',
            attributes: skillAttributes?.filter((attribute) => attribute.subCategory === SkillsSubCategory.Knowledge)
        },
        {
            title: 'Wat ik kan',
            img: '/assets/img/WIHV_3D_Visual_Leerpad.jpg',
            attributes: skillAttributes?.filter((attribute) => attribute.subCategory === SkillsSubCategory.Skills)
        },
    ];

    return (
        <>
            <Container fluid className='bg-paars py-5'>
                <Container>
                    <SubCategoryAttributes
                        attributeSelected={attributeSelected}
                        originSelected={originSelected}
                        onAttributeClick={(a) => { handleAttributeClick(a) }}
                        attributeSubCategories={attributeSubCategories}
                        isLoading={isLoading} />
                </Container>
            </Container>

            <Container fluid className='bg-lichtblauw py-5'>
                <Container>
                    <Row>
                        <ProfileAttributeOrigins
                            title={t('development:skillsOriginsTitle')}
                            attributes={skillAttributes}
                            originSelected={originSelected}
                            attributeSelected={attributeSelected}
                            onOriginClick={(o) => { handleOriginClick(o) }}
                            isLoading={isLoading} />
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default ProfileSkills