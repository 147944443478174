import CTAFullWidth from "@/components/cta/CTAFullWidth";
import ScrollToTopButton from "@/components/scroll-to-top/ScrollToTopButton";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import PageHeader from "@/layout/page-header/PageHeader";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { t } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './LearningOverview.scss';
import FavoriteLearningResources from "./components/FavoriteLearningResources";
import LearningResourceCategories from "./components/LearningResourceCategories";
import LearningResourceList from "./components/LearningResourceList";
import RecommendedLearningResources from "./components/RecommendedLearningResources";
import StartedLearningResources from "./components/StartedLearningResources";
import AnchorButton from "@/components/button/AnchorButton";
import LinkButton from "@/components/button/LinkButton";

function LearningOverview() {

    useDocumentTitle(t('documentTitles:learning'));

    return (

        <div className="learning-overview">

            <UnauthenticatedTemplate>
                <PageHeader image='/assets/img/WIHV_3D_Visual_Rood_Wit.jpg' lightTheme={true}>
                    <Row className='position-relative py-5'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <h1 className='font-heavy text-geel pb-2'>{t('learning:overview:headerTitle')}</h1>
                            <h2 className="font-bold text-wit h3 pb-4">{t('learning:overview:headerSubtitle')}</h2>
                            <p className="text-wit">{t('learning:overview:headerDescription1')}</p>
                            <p className="text-wit">{t('learning:overview:headerDescription2')}</p>
                            <div className='d-flex pt-5'>
                                <AnchorButton linkToElement='#list' text={t('learning:overview:headerCtaBtn')} icon="chevron-right" />
                            </div>
                        </Col>
                    </Row>
                </PageHeader>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <PageHeader image='/assets/img/WIHV_3D_Visual_Rood_Wit.jpg' lightTheme={true}>
                    <Row className='position-relative py-5'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <h1 className='font-heavy text-geel pb-2'>{t('learning:overview:headerTitleProfile')}</h1>
                            <h2 className="font-bold text-wit h3 pb-4">{t('learning:overview:headerSubtitleProfile')}</h2>
                            <p className="text-wit">{t('learning:overview:headerDescriptionProfile1')}</p>
                            <p className="text-wit">{t('learning:overview:headerDescriptionProfile2')}</p>
                            <div className='d-flex pt-4'>
                                <AnchorButton linkToElement='#list' text={t('learning:overview:headerCtaProfileBtn')} icon="chevron-right" />
                            </div>
                        </Col>
                    </Row>
                </PageHeader>
            </AuthenticatedTemplate>

            <RecommendedLearningResources />

            <StartedLearningResources />

            <FavoriteLearningResources />

            <Container fluid className="bg-blauw py-5">
                <Container>
                    <LearningResourceCategories />
                </Container>
            </Container>

            <Container fluid className="gradient-background py-5">
                <Container>
                    <CTAFullWidth
                        title={t('learning:overview:ctaProfileTitle')}
                        description={t('learning:overview:ctaProfileDescription')}
                        imageSrc={'/assets/img/professions/WIHV_Professions_Helpdesk.jpg'}
                        buttonText={t('learning:overview:ctaProfileBtn')}
                        buttonLink={'/profile'} />
                </Container>
            </Container>

            <Container fluid className="bg-lichtblauw py-5">
                <Container>
                    <LearningResourceList />
                </Container>
            </Container>

            <ScrollToTopButton section='list' />
        </div>
    )
}

export default LearningOverview