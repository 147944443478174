import AnchorButton from "@/components/button/AnchorButton";
import CTAFullWidth from "@/components/cta/CTAFullWidth";
import ScrollToTopButton from "@/components/scroll-to-top/ScrollToTopButton";
import useCustomToasts from "@/hooks/useCustomToasts";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import PageHeader from "@/layout/page-header/PageHeader";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import './MeasurementsOverview.scss';
import FavoriteMeasurements from "./components/FavoriteMeasurements";
import MeasurementCategories from "./components/MeasurementCategories";
import MeasurementList from "./components/MeasurementList";
import RecommendedMeasurements from "./components/RecommendedMeasurements";

function MeasurementsOverview() {

    useDocumentTitle(t('documentTitles:measurements'));
    const { showMeasurementsVoucherCTAToast } = useCustomToasts();
    const [skipInView, setSkipInView] = useState(true);

    const { ref, inView } = useInView({
        initialInView: false,
        triggerOnce: true,
        threshold: 0.4,
        skip: skipInView
    });

    useEffect(() => {
        setSkipInView(false);

        if (inView) {
            showMeasurementsVoucherCTAToast();
        }
    }, [inView]);

    return (
        <div className="measurement-overview">
            <UnauthenticatedTemplate>
                <PageHeader image='/assets/img/WIHV_3D_Visual_Geel_Wit.jpg' lightTheme={true}>
                    <Row className='position-relative py-5'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <h1 className='font-heavy text-geel pb-2'>{t('measurements:overview:headerTitle')}</h1>
                            <h2 className="font-bold text-wit h3 pb-4">{t('measurements:overview:headerSubtitle')}</h2>
                            <p className="text-wit">{t('measurements:overview:headerDescription1')}</p>
                            <p className="text-wit">{t('measurements:overview:headerDescription2')}</p>
                            <div className='d-flex pt-5'>
                                <AnchorButton linkToElement='#list' text={t('measurements:overview:headerCtaBtn')} icon="chevron-right" />
                            </div>
                        </Col>
                    </Row>
                </PageHeader>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <PageHeader image='/assets/img/WIHV_3D_Visual_Geel_Wit.jpg' lightTheme={true}>
                    <Row className='position-relative py-5'>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <h1 className='font-heavy text-geel pb-2'>{t('measurements:overview:headerTitleProfile')}</h1>
                            <h2 className="font-bold text-wit h3 pb-4">{t('measurements:overview:headerSubtitleProfile')}</h2>
                            <p className="text-wit">{t('measurements:overview:headerDescriptionProfile1')}</p>
                            <p className="text-wit">{t('measurements:overview:headerDescriptionProfile2')}</p>
                            <div className='d-flex pt-4'>
                                <AnchorButton linkToElement='#list' text={t('measurements:overview:headerCtaProfileBtn')} icon="chevron-right" />
                            </div>
                        </Col>
                    </Row>
                </PageHeader>
            </AuthenticatedTemplate>

            <RecommendedMeasurements />

            <FavoriteMeasurements />

            <Container fluid className="bg-blauw py-5" ref={ref}>
                <Container>
                    <MeasurementCategories />
                </Container>
            </Container>

            <Container fluid className="gradient-background py-5">
                <Container>
                    <CTAFullWidth
                        title={t('measurements:overview:ctaProfileTitle')}
                        description={t('measurements:overview:ctaProfileDescription')}
                        imageSrc={'/assets/img/professions/WIHV_Professions_Helpdesk.jpg'}
                        buttonText={t('measurements:overview:ctaProfileBtn')}
                        buttonLink={'/profile'} />
                </Container>
            </Container>

            <Container fluid className="bg-lichtblauw py-5">
                <Container>
                    <MeasurementList />
                </Container>
            </Container>

            <ScrollToTopButton section='list' />
        </div>
    )
}

export default MeasurementsOverview